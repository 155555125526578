import { useState, useRef } from "react";
import styled from "@emotion/styled";
import {
  Star,
  DollarSign,
  Bell,
  Check,
  CheckCircle,
  ChevronDown,
} from "lucide-react";
import { css, Global, keyframes } from "@emotion/react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { motion } from "framer-motion";

// 1) Import Tippy for tooltips:
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import {
  createCheckOutSesssion,
  saveEmail,
} from "../../functions/apiFunctions";
import { Tweet } from "../../components/TweetContainer";
import { Footer } from "../../components/Footer";
import Masonry from "react-masonry-css";

// Sample tweet data
const sampleTweets = [
  { id: "1884784504511795249" },
  { id: "1866513178353451435" },
  { id: "1713718180936659110" },
];

/* Animations */
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const bounce = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

/* Global Styles */
const GlobalStyles = css`
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
  body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    background: #f0f4f8;
    color: #333;
  }
`;

/* Header Button */
const HeaderButton = styled(Link)`
  position: fixed;
  top: 20px;
  right: 20px;
  background: transparent;
  border: 2px solid #6366f1;
  color: #6366f1;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  font-weight: 700;
  text-decoration: none;
  z-index: 1000;
  transition: all 0.3s ease;

  &:hover {
    background: #6366f1;
    color: #fff;
    transform: scale(1.05);
  }
`;

/* Container & Sections */
const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  background: #f0f4f8;
  overflow-y: auto;
`;

const Section = styled.section`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  color: #333;
`;

/* Hero Section */
const Hero = styled.section`
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #a1c4fd, #c2e9fb);
  overflow: hidden;
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  animation: ${fadeIn} 1.2s ease-out;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const LogoText = styled.span`
  font-size: 2rem;
  font-weight: 700;
  margin-left: 0.5rem;
  color: #6366f1;
`;

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  color: #333;
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Highlight = styled.span`
  color: #6366f1;
`;

const HeroSubtitle = styled.p`
  font-size: 1.75rem;
  color: #555;
  margin-bottom: 2rem;
`;

const CTAButton = styled.button`
  background: linear-gradient(135deg, #6366f1, #8b5cf6);
  color: #fff;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 50px;
  font-weight: 700;
  font-size: 1.125rem;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(99, 102, 241, 0.4);
    animation: ${pulse} 1s infinite;
  }
`;

const DownArrowWrapper = styled.div`
  position: absolute;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  animation: ${bounce} 2s infinite;
  @media (max-width: 768px) {
    bottom: 150px;
  }
`;

/* ---------------- NEW TESTIMONIAL SECTION ---------------- */
const TestimonialSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TestimonialCard = styled.a`
  display: block;
  background: #fff;
  color: #333;
  text-decoration: none;
  border-radius: 1rem;
  padding: 1.5rem;
  max-width: 350px; /* Constrains card width */
  flex: 1 1 300px; /* Helps them stay side-by-side on larger screens */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: relative;
  @media (max-width: 768px) {
    max-height: 100px; /* Constrains card height */
  }

  &:hover {
    transform: translateY(-4px);
  }

  &::before {
    content: "“";
    position: absolute;
    top: -10px;
    left: -10px;
    font-size: 4rem;
    color: #6366f1;
    opacity: 0.15;
    pointer-events: none;
  }

  &::after {
    content: "”";
    position: absolute;
    bottom: -20px;
    right: -10px;
    font-size: 4rem;
    color: #6366f1;
    opacity: 0.15;
    pointer-events: none;
  }
`;

const TestimonialText = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
`;
/* --------------------------------------------------------- */

/* Feature Cards */
const FeatureCard = styled.div`
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
`;

const FeatureIcon = styled.div`
  background: #eef2ff;
  color: #6366f1;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const FeatureDescription = styled.p`
  color: #555;
  font-size: 0.9rem;
`;

/* Subscription Cards */
const SubscriptionCard = styled(motion.div)`
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  flex: 1;
  display: flex;
  width: calc(100% - 60px);
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  }
`;

const SubscriptionTitle = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  color: #6366f1;
  margin-bottom: 1rem;
`;

const SubscriptionPrice = styled.p`
  font-size: 2.5rem;
  font-weight: 800;
  color: #10b981;
  margin-bottom: 1rem;
`;

const SubscriptionDescription = styled.p`
  font-size: 1rem;
  color: #555;
  text-align: center;
  margin-bottom: 2rem;
`;

const SubscriptionButton = styled(CTAButton)`
  margin-top: auto;
  border-radius: 50px;
  text-decoration: none;
`;

/* Subscription Cards Container */
const SubscriptionCardsContainer = styled(Section)`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

/* Email Signup Section */
const EmailSignupSection = styled(Section)`
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

/* Masonry Styled Component */
const StyledMasonry = styled(Masonry)`
  display: flex;
  margin-left: -20px;
  width: auto;

  & .my-masonry-grid_column {
    padding-left: 20px;
    background-clip: padding-box;
  }
`;

export const LandingPageB = ({ abTestCase }) => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");
  const containerRef = useRef(null);
  const { isAuthenticated } = useAuth0();

  const validateEmail = (email) =>
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    saveEmail(email);
    setError("");
    console.log("Email submitted:", email);
    setIsSubmitted(true);
    setEmail("");
  };

  const handleOrderClick = () => {
    console.log("Order clicked with test case:", abTestCase);
    createCheckOutSesssion({ basic: false, abTestCase });
  };

  const handleArrowClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.clientHeight,
        behavior: "smooth",
      });
    }
  };

  // Masonry breakpoints for tweets
  const breakpointColumnsObj = {
    default: 3,
    768: 1,
  };

  return (
    <>
      <Global styles={GlobalStyles} />
      <Helmet>
        <title>WillPayForThis.com - Discover market demand</title>
        <meta
          name="description"
          content="Be the first to know when willpayforthis.com launches. Discover market-validated ideas."
        />
        <link rel="canonical" href="https://www.willpayforthis.com/" />
      </Helmet>

      <HeaderButton to="/library">Sign In</HeaderButton>

      <Container ref={containerRef}>
        {/* HERO SECTION */}
        <Hero>
          <HeroContent>
            <LogoWrapper>
              <DollarSign size={48} color="#6366f1" />
              <LogoText>willpayforthis</LogoText>
            </LogoWrapper>
            <HeroTitle>
              Discover What People <Highlight>Will Pay For</Highlight>
            </HeroTitle>
            <HeroSubtitle>
              Your shortcut to market-validated ideas.
            </HeroSubtitle>

            {/* NEW TESTIMONIALS "ABOVE THE FOLD" */}
            <TestimonialSection>
              <Tippy content="Click to go to source">
                <TestimonialCard
                  href="https://x.com/AlfredSimon/status/1891846817886736652"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TestimonialText>
                    My new hack is to use the tool willpayforthis.com. Gives me
                    great ideas and then I'll look into if there is any interest
                    at all or just a wild tweet. I already have 3 potential
                    tools on my list.
                  </TestimonialText>
                </TestimonialCard>
              </Tippy>

              <Tippy content="Click to go to source">
                <TestimonialCard
                  href="https://x.com/dalib0rg/status/1889605127410962617"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TestimonialText>
                    You can also check http://willpayforthis.com ... monitors X
                    for best product ideas. It's quite a cool product.
                  </TestimonialText>
                </TestimonialCard>
              </Tippy>
            </TestimonialSection>

            {/* Email Errors / Success */}
            {error && <p style={{ color: "#f87171" }}>{error}</p>}
            {isSubmitted && (
              <p style={{ color: "#10b981", fontWeight: 600 }}>
                <CheckCircle size={16} /> Thank you! We'll notify you when we
                launch.
              </p>
            )}
          </HeroContent>
          <DownArrowWrapper onClick={handleArrowClick}>
            <ChevronDown size={32} color="#6366f1" />
          </DownArrowWrapper>
        </Hero>

        {/* TWEETS / EXPLORE SECTION */}
        <Section>
          <SectionTitle>
            <Star size={24} color="#6366f1" /> Sneak Peek: What People Want
          </SectionTitle>
          <StyledMasonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {sampleTweets.map((tweet) => (
              <Tweet key={tweet.id} tweet={tweet} />
            ))}
          </StyledMasonry>
        </Section>

        {/* FEATURES SECTION */}
        <Section>
          <SectionTitle>
            <DollarSign size={24} color="#6366f1" /> Why Join WillPayForThis?
          </SectionTitle>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
              gap: "2rem",
            }}
          >
            <FeatureCard>
              <FeatureIcon>
                <DollarSign size={32} />
              </FeatureIcon>
              <FeatureTitle>How it works</FeatureTitle>
              <FeatureDescription>
                It's simple. People are saying what they want online – we gather
                it all in one place.
              </FeatureDescription>
            </FeatureCard>
            <FeatureCard>
              <FeatureIcon>
                <Bell size={32} />
              </FeatureIcon>
              <FeatureTitle>Product Ideas</FeatureTitle>
              <FeatureDescription>
                Hundreds of tweets from people discussing products they'd pay
                for.
              </FeatureDescription>
            </FeatureCard>
            <FeatureCard>
              <FeatureIcon>
                <Star size={32} />
              </FeatureIcon>
              <FeatureTitle>Twitter Research</FeatureTitle>
              <FeatureDescription>
                Curated tweets to validate ideas or inspire new product
                concepts.
              </FeatureDescription>
            </FeatureCard>
          </div>
        </Section>

        {/* VIDEO DEMO SECTION */}
        <Section>
          <SectionTitle>
            <Star size={24} color="#6366f1" /> Check Out Our Quick Demo
          </SectionTitle>
          <p
            style={{
              color: "#555",
              textAlign: "center",
              maxWidth: "800px",
              margin: "0 auto",
            }}
          >
            Watch this video to see how WillPayForThis can help you discover
            market-validated ideas.
          </p>
          <video
            controls
            style={{
              width: "100%",
              maxWidth: "100%",
              borderRadius: "0.5rem",
              marginTop: "2rem",
            }}
          >
            <source src="/demo-2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Section>

        {/* SUBSCRIPTION CARDS SECTION */}
        <SubscriptionCardsContainer>
          <SubscriptionCard
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 100, damping: 15 }}
          >
            <div style={{ textAlign: "center" }}>
              <SubscriptionTitle>Free Preview</SubscriptionTitle>
              <p style={{ color: "#555", marginBottom: "1rem" }}>
                Explore 9 recent posts in our library with a free preview.
              </p>
            </div>
            <SubscriptionButton as={Link} to="/library-preview?lifetime=true">
              Free Preview
            </SubscriptionButton>
          </SubscriptionCard>

          <SubscriptionCard>
            <div style={{ textAlign: "center" }}>
              <SubscriptionTitle>Lifetime Access</SubscriptionTitle>
              <SubscriptionPrice>$30</SubscriptionPrice>
              <p style={{ color: "#555", marginBottom: "1rem" }}>
                Access hundreds of unique ideas.
              </p>
              <ul style={{ listStyle: "none", padding: 0 }}>
                <li
                  style={{
                    marginBottom: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    color: "#333",
                  }}
                >
                  <Check
                    size={20}
                    style={{ marginRight: "0.5rem", color: "#10b981" }}
                  />
                  Database access
                </li>
                <li
                  style={{
                    marginBottom: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    color: "#333",
                  }}
                >
                  <Check
                    size={20}
                    style={{ marginRight: "0.5rem", color: "#10b981" }}
                  />
                  Monthly tweet updates
                </li>
                <li
                  style={{
                    marginBottom: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    color: "#333",
                  }}
                >
                  <Check
                    size={20}
                    style={{ marginRight: "0.5rem", color: "#10b981" }}
                  />
                  Unlimited searches
                </li>
                <li
                  style={{
                    marginBottom: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    color: "#333",
                  }}
                >
                  <Check
                    size={20}
                    style={{ marginRight: "0.5rem", color: "#10b981" }}
                  />
                  Save favorites
                </li>
              </ul>
            </div>
            <SubscriptionButton onClick={handleOrderClick}>
              Get Started
            </SubscriptionButton>
          </SubscriptionCard>
        </SubscriptionCardsContainer>

        {/* EMAIL SIGNUP SECTION */}
        <EmailSignupSection>
          <SectionTitle style={{ justifyContent: "center" }}>
            <Bell size={24} color="#6366f1" /> Stay Updated
          </SectionTitle>
          <div style={{ textAlign: "center" }}>
            <p style={{ color: "#555", marginBottom: "1.5rem" }}>
              Get notified when we launch and receive exclusive early access.
            </p>
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                maxWidth: "500px",
                margin: "0 auto",
                gap: "1rem",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{
                  flex: 1,
                  padding: "0.75rem 1rem",
                  borderRadius: "50px",
                  border: "1px solid #ccc",
                  fontSize: "1rem",
                  outline: "none",
                }}
              />
              <CTAButton type="submit">
                {isSubmitted ? <CheckCircle size={20} /> : "Notify Me"}
              </CTAButton>
            </form>
            {error && (
              <p style={{ color: "#f87171", marginTop: "0.5rem" }}>{error}</p>
            )}
            {isSubmitted && (
              <p
                style={{
                  color: "#10b981",
                  marginTop: "1rem",
                  fontWeight: 600,
                }}
              >
                <CheckCircle size={16} /> Thank you! We'll notify you when we
                launch.
              </p>
            )}
          </div>
          <p
            style={{
              fontSize: "0.8rem",
              color: "#a1a1aa",
              textAlign: "center",
              marginTop: "1rem",
            }}
          >
            By entering your email, you agree to receive updates and newsletters
            from willpayforthis@gmail.com.
          </p>
        </EmailSignupSection>

        <Footer />
      </Container>
    </>
  );
};

export default LandingPageB;
