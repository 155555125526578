// src/components/Footer/Footer.js
import React from "react";
import styled from "@emotion/styled";
import { DollarSign } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <FooterContainer>
      <FooterContent>
        {/* Left Column: Logo and Copyright */}
        <FooterColumn className="brand-column">
          <FooterLogo onClick={handleLogoClick}>
            <DollarSign size={24} />
            <LogoText>willpayforthis</LogoText>
          </FooterLogo>
        </FooterColumn>

        {/* Middle Column: General Links */}
        <FooterColumn className="links-column">
          <FooterHeading>Company</FooterHeading>
          <FooterLinks>
            <FooterExternalLink
              href="https://x.com/josh_bickett/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Me!
            </FooterExternalLink>

            <FooterInternalLink to="/privacy-policy">
              Privacy Policy
            </FooterInternalLink>
            <FooterInternalLink to="/terms-of-use">
              Terms of Use
            </FooterInternalLink>
          </FooterLinks>
        </FooterColumn>

        {/* Right Column: Resources */}
        <FooterColumn className="resources-column">
          <FooterHeading>Resources</FooterHeading>
          <FooterLinks>
            <FooterInternalLink to="/blog">Blog</FooterInternalLink>
            <FooterInternalLink to="/k/the-lean-startup">
              The Lean Startup
            </FooterInternalLink>
            <FooterInternalLink to="/k/stealth-startup">
              Stealth Startup
            </FooterInternalLink>
          </FooterLinks>
        </FooterColumn>
      </FooterContent>
      <FooterBottom>
        <Copyright>
          &copy; {new Date().getFullYear()} WillPayForThis. All rights reserved.
        </Copyright>
      </FooterBottom>
    </FooterContainer>
  );
};

/* Styled Components */

const FooterContainer = styled.footer`
  background: rgba(255, 255, 255, 0.05);
  padding: 2.5rem 0 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  gap: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2.5rem;
    align-items: flex-start;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &.brand-column {
    flex-basis: 40%;
  }

  &.links-column,
  &.resources-column {
    flex-basis: 30%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FooterLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  margin-bottom: 0.5rem;

  &:hover {
    opacity: 0.9;
  }
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
`;

const FooterTagline = styled.p`
  color: #94a3b8;
  font-size: 0.9rem;
  margin-top: 0.25rem;
`;

const FooterHeading = styled.h3`
  color: #e2e8f0;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const FooterInternalLink = styled(Link)`
  color: #94a3b8;
  text-decoration: none;
  transition: color 0.2s ease;
  font-size: 0.95rem;

  &:hover {
    color: #6366f1;
  }
`;

const FooterExternalLink = styled.a`
  color: #94a3b8;
  text-decoration: none;
  transition: color 0.2s ease;
  font-size: 0.95rem;

  &:hover {
    color: #6366f1;
  }
`;

const FooterBottom = styled.div`
  max-width: 1200px;
  margin: 2rem auto 0;
  padding: 1.25rem 2rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const Copyright = styled.p`
  color: #64748b;
  font-size: 0.875rem;
  text-align: center;
`;
