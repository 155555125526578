// src/pages/BlogPage.js
import React from "react";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Footer } from "../components/Footer";

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  background: #0f172a;
  color: #f8fafc;
  font-family: "Poppins", sans-serif;
`;

const BlogHeader = styled.header`
  background: linear-gradient(135deg, #1e293b 0%, #0f172a 100%);
  padding: 4rem 2rem;
  text-align: center;
`;

const BlogTitle = styled.h1`
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 1rem;
`;

const BlogSubtitle = styled.p`
  font-size: 1.5rem;
  color: #94a3b8;
`;

const PostsSection = styled.section`
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 2rem;
`;

const PostsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;
`;

const BlogCard = styled.article`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  padding: 2rem;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const CardTitle = styled.h2`
  font-size: 1.75rem;
  margin-bottom: 1rem;
  color: #ffffff;
`;

const CardDate = styled.p`
  font-size: 0.875rem;
  color: #94a3b8;
  margin-bottom: 1rem;
`;

const CardExcerpt = styled.p`
  font-size: 1rem;
  color: #cbd5e1;
  margin-bottom: 1rem;
`;

const ReadMoreLink = styled(Link)`
  display: inline-block;
  margin-top: 1rem;
  color: #6366f1;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

const sampleBlogPosts = [
  {
    id: 1,
    title: "How to Find Market Demand: Uncover What People Will Pay For",
    date: "Feb. 27, 2025",
    excerpt:
      "Learn how to uncover market demand by leveraging advanced social media searches and direct engagement. Discover actionable strategies to validate your product ideas.",
    link: "/blog/how-to-find-market-demand",
  },
];

export const BlogPage = () => {
  return (
    <>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
          body {
            margin: 0;
            padding: 0;
          }
        `}
      />
      <Helmet>
        <title>WillPayForThis Blog - Insights and Updates</title>
        <meta
          name="description"
          content="Read the latest insights and updates from WillPayForThis. Discover market trends and behind-the-scenes stories."
        />
        <link rel="canonical" href="https://www.willpayforthis.com/blog" />
      </Helmet>
      <Container>
        <BlogHeader>
          <BlogTitle>WillPayForThis Blog</BlogTitle>
          <BlogSubtitle>
            Your source for market insights and updates
          </BlogSubtitle>
        </BlogHeader>
        <PostsSection>
          <PostsGrid>
            {sampleBlogPosts.map((post) => (
              <BlogCard key={post.id}>
                <CardTitle>{post.title}</CardTitle>
                <CardDate>{post.date}</CardDate>
                <CardExcerpt>{post.excerpt}</CardExcerpt>
                <ReadMoreLink to={post.link}>Read More</ReadMoreLink>
              </BlogCard>
            ))}
          </PostsGrid>
        </PostsSection>
        <Footer />
      </Container>
    </>
  );
};

export default BlogPage;
