import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";
import { Search, DollarSign } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Tweet } from "../components/TweetContainer";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { loadPosts, setStatus } from "../functions/apiFunctions"; // Updated import
import { Header } from "../components/Header";

const PAGE_SIZE = 4; // Adjust as needed

// Debounce function
const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
      timer = null;
    }, delay);
  };
};

// Define status constants for clarity
const STATUS = {
  AI_APPROVED: "ai-approved",
  AI_REJECTED: "ai-rejected",
  USER_APPROVED: "user-approved",
  USER_REJECTED: "user-rejected",
};

// Reducer for managing posts
const postsReducer = (state, action) => {
  switch (action.type) {
    case "SET_POSTS":
      return { ...state, allPosts: action.payload };
    case "REMOVE_POST":
      return {
        ...state,
        allPosts: state.allPosts.filter((post) => post.id !== action.payload),
      };
    case "ADD_POST":
      return { ...state, allPosts: [action.payload, ...state.allPosts] };
    case "UPDATE_STATUS":
      // Prevent state update if status is already the desired value
      const existingPost = state.allPosts.find(
        (post) => post.id === action.payload.id
      );
      if (existingPost && existingPost.status === action.payload.status) {
        return state; // No change needed
      }
      return {
        ...state,
        allPosts: state.allPosts.map((post) =>
          post.id === action.payload.id
            ? { ...post, status: action.payload.status }
            : post
        ),
      };
    default:
      return state;
  }
};

// Notification Component
const Notification = ({ message, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000); // Notification disappears after 3 seconds
    return () => clearTimeout(timer);
  }, [onClose]);

  return <NotificationContainer type={type}>{message}</NotificationContainer>;
};

const Agent = () => {
  const { logout, isAuthenticated, getAccessTokenSilently, isLoading, user } =
    useAuth0();

  const [state, dispatch] = useReducer(postsReducer, {
    allPosts: [],
  });
  const { allPosts } = state;

  // Keep track of which view is currently active
  const [view, setView] = useState("queue"); // "queue" or "saved"

  const [displayedPosts, setDisplayedPosts] = useState([]);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false); // New state
  const [showBackToTop, setShowBackToTop] = useState(false);

  const [accessLoading, setAccessLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [notification, setNotification] = useState(null);

  const navigate = useNavigate();
  const loadMoreRef = useRef(null);
  const containerRef = useRef(null);
  const searchBarRef = useRef(null);

  // Handle input changes for search term
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  // Load posts from the new endpoint
  const performLoadPosts = async () => {
    try {
      if (!isAuthenticated) {
        setAccessLoading(false);
        return;
      }
      const accessToken = await getAccessTokenSilently();
      const data = await loadPosts(accessToken);

      console.log("Posts data", data);
      if (data.access) {
        dispatch({ type: "SET_POSTS", payload: data.posts });
      } else {
        console.log("Access denied");
      }

      setAccessLoading(false);
    } catch (err) {
      console.error("Error during loading posts:", err);

      setAccessLoading(false);
      setNotification({
        message: "Failed to load posts.",
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      performLoadPosts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  // Memoize agentQueuePosts and savedPosts to prevent unnecessary re-renders
  const agentQueuePosts = useMemo(
    () => allPosts.filter((post) => post.status === STATUS.AI_APPROVED),
    [allPosts]
  );

  const savedPosts = useMemo(
    () => allPosts.filter((post) => post.status === STATUS.USER_APPROVED),
    [allPosts]
  );

  // Filter the relevant list (queue or saved) based on search term
  useEffect(() => {
    const term = (searchTerm || "").toLowerCase();
    let relevantPosts = view === "queue" ? agentQueuePosts : savedPosts;

    let newFiltered = relevantPosts.filter((post) => {
      // Simple text content filter
      return post.content?.toLowerCase().includes(term);
    });

    setDisplayedPosts(newFiltered);
    setLimit(PAGE_SIZE);
  }, [searchTerm, view, agentQueuePosts, savedPosts]);

  // Debounced Intersection Handler
  const handleIntersection = useCallback(
    debounce((entry) => {
      if (entry.isIntersecting && !loading) {
        setLoading(true);
        setTimeout(() => {
          setLimit((prevLimit) => prevLimit + PAGE_SIZE);
          setLoading(false);
        }, 250);
      }
    }, 600),
    [loading]
  );

  // Scroll "Load More"
  useEffect(() => {
    if (!containerRef.current) return;
    // If we've loaded all the posts, no need to observe
    if (limit >= displayedPosts.length) return;

    const observerOptions = {
      root: containerRef.current,
      threshold: 0.5,
      rootMargin: "0px 0px 100px 0px",
    };

    const observer = new IntersectionObserver(([entry]) => {
      handleIntersection(entry);
    }, observerOptions);

    if (loadMoreRef.current) observer.observe(loadMoreRef.current);

    return () => {
      if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
    };
  }, [limit, displayedPosts.length, loading, handleIntersection]);

  // Back to Top
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      if (container.scrollTop > 300) setShowBackToTop(true);
      else setShowBackToTop(false);
    };
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    containerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  // Handler to approve or reject a post
  const handleSetUserApproval = async (postId, userApproved) => {
    try {
      setProcessing(true); // Disable buttons during processing
      const accessToken = await getAccessTokenSilently();
      const status = userApproved ? STATUS.USER_APPROVED : STATUS.USER_REJECTED;

      const response = await setStatus(accessToken, postId, status);
      console.log("Set status response:", response);

      if (response) {
        // Update the post's status in the state
        dispatch({
          type: "UPDATE_STATUS",
          payload: { id: postId, status },
        });

        setNotification({
          message: `Post has been ${
            userApproved ? "approved and saved" : "rejected"
          }.`,
          type: "success",
        });
      } else {
        throw new Error("Set status failed on the server.");
      }
    } catch (error) {
      console.error(`Error updating post status:`, error);
      setNotification({
        message: `Failed to ${userApproved ? "approve" : "reject"} the post.`,
        type: "error",
      });
    } finally {
      setProcessing(false); // Re-enable buttons after processing
    }
  };

  // Paginate the final displayed posts
  const paginatedPosts = displayedPosts.slice(0, limit);

  // Loading screen while checking access
  if (accessLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <LoadingText>Loading Posts...</LoadingText>
      </LoadingContainer>
    );
  }

  return (
    <>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
          *,
          *::before,
          *::after {
            box-sizing: border-box;
          }
          body {
            margin: 0;
            padding: 0;
            font-family: "Poppins", -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
              "Helvetica Neue", sans-serif;
            background: #0f172a;
            color: #f8fafc;
            overflow-x: hidden; /* Prevent horizontal scroll */
          }
        `}
      />
      <Container ref={containerRef}>
        <Header />

        <MainContent>
          {/* Search Bar */}
          <SearchBar>
            <SearchIcon>
              <Search size={20} />
            </SearchIcon>
            <SearchInput
              type="text"
              placeholder="Search posts..."
              value={searchTerm}
              onChange={handleSearchChange}
              aria-label="Search Posts"
            />
            <RecordsCount aria-live="polite">
              {displayedPosts.length} post
              {displayedPosts.length !== 1 ? "s" : ""}
            </RecordsCount>
          </SearchBar>

          {/* Toggle Buttons for the two sub-views */}
          <ViewToggleContainer>
            <ToggleButton
              active={view === "queue"}
              onClick={() => setView("queue")}
            >
              Queue
            </ToggleButton>
            <ToggleButton
              active={view === "saved"}
              onClick={() => setView("saved")}
            >
              Saved
            </ToggleButton>
          </ViewToggleContainer>

          {/* Posts List */}
          <PostContainerWrapper>
            {paginatedPosts.length > 0 ? (
              paginatedPosts.map((post) => (
                <PostWrapper key={post.id}>
                  {/* Show buttons only in "queue" view */}
                  {view === "queue" && (
                    <ButtonRow>
                      <SaveButton
                        onClick={() => handleSetUserApproval(post.id, true)}
                        aria-label={`Approve post ${post.id}`}
                        disabled={loading || processing}
                      >
                        Save
                      </SaveButton>
                      <RejectButton
                        onClick={() => handleSetUserApproval(post.id, false)}
                        aria-label={`Reject post ${post.id}`}
                        disabled={loading || processing}
                      >
                        Reject
                      </RejectButton>
                    </ButtonRow>
                  )}

                  <PostCenter>
                    <TweetContainer>
                      <Tweet tweet={post} />
                    </TweetContainer>
                  </PostCenter>
                </PostWrapper>
              ))
            ) : (
              <NoPostsMessage>No posts to display.</NoPostsMessage>
            )}
          </PostContainerWrapper>

          {limit < displayedPosts.length && (
            <LoadMoreMarker ref={loadMoreRef}>
              {loading ? (
                <Spinner />
              ) : (
                <LoadingText>Scroll to load more</LoadingText>
              )}
            </LoadMoreMarker>
          )}

          {/* Notification */}
          {notification && (
            <Notification
              message={notification.message}
              type={notification.type}
              onClose={() => setNotification(null)}
            />
          )}
        </MainContent>

        <Footer>
          <FooterContent>
            <FooterLogo>
              <DollarSign size={24} />
              <LogoText>willpayforthis</LogoText>
            </FooterLogo>
            <FooterLinks>
              <FooterLink href="https://x.com/josh_bickett/">
                Message me about questions or provide input
              </FooterLink>
            </FooterLinks>
          </FooterContent>
          <FooterBottom>
            <Copyright>
              &copy; 2025 WillPayForThis. All rights reserved.
            </Copyright>
          </FooterBottom>
        </Footer>

        {showBackToTop && (
          <BackToTopButton onClick={scrollToTop} aria-label="Back to Top">
            ↑ Top
          </BackToTopButton>
        )}
      </Container>
    </>
  );
};

export default withAuthenticationRequired(Agent);

/* --- Styled Components Below --- */

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  position: relative;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: 0.5rem;
`;

const MainContent = styled.main`
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem; /* Reduced padding for smaller screens */
`;

const SearchBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 480px) {
    padding: 0.5rem;
    gap: 0.3rem;
  }
`;

const SearchIcon = styled.span`
  color: #94a3b8;
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input`
  flex: 1 1 auto;
  background: transparent;
  border: none;
  color: #f8fafc;
  font-size: 1rem;
  outline: none;

  &::placeholder {
    color: #94a3b8;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const RecordsCount = styled.span`
  flex: 0 0 auto;
  color: #94a3b8;
  font-size: 0.9rem;

  @media (max-width: 480px) {
    display: none; /* Hide on very small screens to save space */
  }
`;

/* Toggle Container + Buttons */
const ViewToggleContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  justify-content: center;

  @media (max-width: 480px) {
    gap: 0.5rem;
  }
`;

const ToggleButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  color: #ffffff;
  background: ${(props) => (props.active ? "#6366f1" : "#4b5563")};
  transition: background 0.2s;

  &:hover {
    background: ${(props) => (props.active ? "#4f46e5" : "#6b7280")};
  }

  @media (max-width: 480px) {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }
`;

/* Posts Container */
const PostContainerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const PostWrapper = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  min-height: 300px;
  /* Removed fixed min-height to allow flexibility */
  position: relative;
  padding: 10px; /* Increased padding for better touch targets */
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent overflow */
  word-wrap: break-word;

  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 480px) {
    padding: 8px;
  }
`;

/* Container for Approve/Reject buttons */
const ButtonRow = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  gap: 0.5rem;

  @media (max-width: 480px) {
    top: 0.3rem;
    right: 0.3rem;
    gap: 0.3rem;
  }
`;

const SaveButton = styled.button`
  background: #10b981;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0.4rem 0.6rem;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  transition: background 0.2s;

  &:hover {
    background: #059669;
  }

  &:active {
    transform: translateY(1px);
  }

  &:disabled {
    background: #a5b4fc;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 0.3rem 0.5rem;
    font-size: 0.7rem;
  }
`;

const RejectButton = styled.button`
  background: #f87171;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0.4rem 0.6rem;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  transition: background 0.2s;

  &:hover {
    background: #ef4444;
  }

  &:active {
    transform: translateY(1px);
  }

  &:disabled {
    background: #fca5a5;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 0.3rem 0.5rem;
    font-size: 0.7rem;
  }
`;

/* Container to constrain Tweet component */
const TweetContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

/* Centers the Post content */
const PostCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem; /* Add padding to prevent content from touching edges */

  @media (max-width: 480px) {
    padding: 0.8rem;
  }
`;

const PostIdLabel = styled.div`
  margin-top: 0.5rem;
  font-size: 0.8rem;
  color: #94a3b8;

  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
`;

const NoPostsMessage = styled.p`
  color: #94a3b8;
  font-size: 1rem;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const LoadMoreMarker = styled.div`
  width: 100%;
  padding: 1rem;
  text-align: center;
`;

const LoadingText = styled.span`
  color: #94a3b8;
  font-size: 0.9rem;
`;

const Spinner = styled.div`
  width: 32px;
  height: 32px;
  margin: 0 auto;
  border: 4px solid #94a3b8;
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const Footer = styled.footer`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem 0;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const FooterLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 1.5rem;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterLink = styled.a`
  color: #94a3b8;
  text-decoration: none;
  transition: color 0.2s;
  &:hover {
    color: #6366f1;
  }
`;

const FooterBottom = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const Copyright = styled.p`
  color: #64748b;
  font-size: 0.875rem;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 0.75rem;
  }
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999;
  background: #6366f1;
  color: #ffffff;
  border: none;
  border-radius: 9999px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.2s;

  &:hover {
    background: #4f46e5;
  }

  @media (max-width: 480px) {
    bottom: 1.5rem;
    right: 1.5rem;
    padding: 0.6rem 0.9rem;
    font-size: 0.9rem;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LoadingSpinner = styled.div`
  width: 48px;
  height: 48px;
  border: 6px solid #94a3b8;
  border-top-color: #6366f1;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

/* Notification Container */
const NotificationContainer = styled.div`
  position: fixed;
  top: 1rem;
  right: 1rem;
  background: ${({ type }) =>
    type === "success" ? "#10b981" : type === "error" ? "#f87171" : "#3b82f6"};
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out, fadeOut 0.3s ease-in-out 2.7s;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }

  @media (max-width: 480px) {
    width: 90%;
    right: 5%;
    padding: 0.6rem 1rem;
    font-size: 0.85rem;
  }
`;
