import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { ChevronDown } from "lucide-react";

const DropdownContainer = styled.div`
  position: relative;
  width: 250px;

  @media (max-width: 768px) {
    width: 200px;
  }
`;

const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1e293b;
  color: #f8fafc;
  padding: 0.4rem 1rem;
  border-radius: 9999px;
  cursor: pointer;
  font-size: 0.9rem;
  user-select: none;

  &:hover {
    background: #334155;
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  background: #1e293b;
  border: 1px solid #94a3b8;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;

  list-style: none;
  padding: 0;
  margin: 0;

  /* Hide scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const DropdownListItem = styled.li`
  padding: 0.6rem 1rem;
  color: #f8fafc;
  cursor: pointer;
  text-align: left;

  &:hover,
  &:focus {
    background: #334155;
    outline: none;
  }
`;

export const CustomDropdown = ({ options, value, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const firstOptionRef = useRef(null);

  // Toggle dropdown open state
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Handle option selection
  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle keyboard navigation
  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      toggleDropdown();
    } else if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  const handleOptionKeyDown = (e, option) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      handleOptionClick(option);
    }
  };

  // Focus the first option when dropdown opens
  useEffect(() => {
    if (isOpen && firstOptionRef.current) {
      firstOptionRef.current.focus();
    }
  }, [isOpen]);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownHeader
        onClick={toggleDropdown}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        isOpen={isOpen}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        role="button"
      >
        <span>{value || placeholder}</span>
        <ChevronDown size={20} />
      </DropdownHeader>
      {isOpen && (
        <DropdownList role="listbox">
          {options.map((option, index) => (
            <DropdownListItem
              key={index}
              onClick={() => handleOptionClick(option)}
              onKeyDown={(e) => handleOptionKeyDown(e, option)}
              tabIndex={0}
              role="option"
              aria-selected={value === option}
              ref={index === 0 ? firstOptionRef : null} // Reference the first option for focus
            >
              {option}
            </DropdownListItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};
