import { useEffect } from "react";
import { createCheckOutSesssion } from "../functions/apiFunctions";

export const Order = () => {
  useEffect(() => {
    const initiateCheckout = async () => {
      try {
        // Call the checkout session API
        const response = await createCheckOutSesssion({ basic: false });

        // Assuming the API returns a `url` for Stripe checkout
        if (response?.url) {
          window.location.href = response.url; // Redirect user
        } else {
          console.error("Failed to retrieve checkout URL.");
        }
      } catch (error) {
        console.error("Error initiating checkout session:", error);
      }
    };

    initiateCheckout();
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h2>Redirecting you to checkout...</h2>
      <p>
        If you're not redirected,{" "}
        <a href="#" onClick={() => window.location.reload()}>
          click here
        </a>
        .
      </p>
    </div>
  );
};
