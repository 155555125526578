import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { DollarSign, LogOut, Cpu, BookOpen, Settings } from "lucide-react";

export const Header = ({ userType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, logout } = useAuth0();
  const agentRolledOut = false;

  // Click the main logo
  const handleLogoClick = () => {
    navigate("/");
  };

  // Logout handler
  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  // Navigation handlers
  const handleGoToAgent = () => {
    navigate("/agent");
  };
  const handleGoToLibrary = () => {
    navigate("/library");
  };

  // New handler for settings
  const handleSettingsClick = () => {
    navigate("/settings");
  };

  // Active detection based on route
  const isAgentActive = location.pathname === "/agent";
  const isLibraryActive =
    location.pathname === "/library" || location.pathname === "/settings";

  const isLibraryPreviewActive = location.pathname === "/library-preview";

  return (
    <Container>
      {/* LEFT SIDE: Logo, DB label, Beta */}
      <HeaderLeft>
        <LogoWrapper onClick={handleLogoClick}>
          <DollarSign size={32} />
          <LogoText>willpayforthis</LogoText>
        </LogoWrapper>

        {isLibraryActive && (
          <DatabaseLifetimeAccessLabel>
            DATABASE LIFETIME ACCESS
          </DatabaseLifetimeAccessLabel>
        )}

        <BetaBadge>{isLibraryPreviewActive ? "Preview" : "Beta"}</BetaBadge>
      </HeaderLeft>

      {/* RIGHT SIDE: AI Agent, Library, Settings (for monthly users), Logout */}
      {isAuthenticated && (
        <HeaderRight>
          {agentRolledOut && (
            <NavButton
              active={isAgentActive}
              // Disable click if this button is already active
              onClick={!isAgentActive ? handleGoToAgent : undefined}
              aria-label="Go to AI Agent"
            >
              <Cpu size={16} style={{ marginRight: "0.3rem" }} />
              <ButtonText>AI Agent</ButtonText>
            </NavButton>
          )}

          {agentRolledOut && (
            <NavButton
              active={isLibraryActive}
              onClick={!isLibraryActive ? handleGoToLibrary : undefined}
              aria-label="Go to Library"
            >
              {/* Distinct icon for Library */}
              <BookOpen size={16} style={{ marginRight: "0.3rem" }} />
              <ButtonText>Library</ButtonText>
            </NavButton>
          )}

          {/* Conditionally render the Settings button for monthly users */}
          {userType === "basic" && (
            <SettingsButton onClick={handleSettingsClick} aria-label="Settings">
              <Settings size={16} style={{ marginRight: "0.3rem" }} />
              <ButtonText>Settings</ButtonText>
            </SettingsButton>
          )}

          <LogoutButton onClick={handleLogout} aria-label="Logout">
            <LogOut size={16} style={{ marginRight: "0.3rem" }} />
            <LogoutText>Logout</LogoutText>
          </LogoutButton>
        </HeaderRight>
      )}
    </Container>
  );
};

/* --- Styled Components --- */

const Container = styled.header`
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

/* LOGO WRAPPER */
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: 0.5rem;

  @media (max-width: 768px) {
    display: none; /* Hide on mobile if desired */
  }
`;

/* LABELS */
const DatabaseLifetimeAccessLabel = styled.span`
  background-color: #10b981; /* Green */
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.3rem 0.7rem;
  border-radius: 4px;
  margin-left: 0.75rem;
  letter-spacing: 0.5px;
  cursor: default;
`;

const BetaBadge = styled.span`
  margin-left: 0.75rem;
  background: #ec4899;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.3rem 0.7rem;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: default;

  @media (max-width: 768px) {
    display: none;
  }
`;

/* NAV BUTTON */
const NavButton = styled.button`
  display: flex;
  align-items: center;
  background: ${({ active }) => (active ? "#a78bfa" : "#8b5cf6")};
  color: #ffffff;
  border: none;
  border-radius: 9999px; /* Pill-shaped */
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  margin-right: 0.75rem;
  transition: background 0.2s, transform 0.2s;
  /* If this button is active, fade it out to indicate "disabled" */
  opacity: ${({ active }) => (active ? 0.55 : 1)};
  /* Remove pointer events if active to truly "disable" */
  pointer-events: ${({ active }) => (active ? "none" : "auto")};

  &:hover {
    background: ${({ active }) => (active ? "#a78bfa" : "#bfa5ff")};
    transform: ${({ active }) => (active ? "none" : "translateY(-1px)")};
  }

  &:active {
    transform: translateY(0);
  }
`;

// Styled component for the Settings button, similar to NavButton.
const SettingsButton = styled(NavButton)`
  /* Optionally override styles for the Settings button here */
  cursor: pointer;
`;

const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  background: #4b5563; /* Gray-blue */
  color: #ffffff;
  border: none;
  border-radius: 9999px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background 0.2s, transform 0.2s;

  &:hover {
    background: #6b7280;
    transform: translateY(-1px);
  }
  &:active {
    transform: translateY(0);
  }
`;

/* Hide text on smaller screens if desired */
const ButtonText = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;
const LogoutText = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;
