// AccessUnavailablePage.js
import React from "react";
import styled from "@emotion/styled";
import { Global } from "@emotion/react";
import { Star } from "lucide-react";
import { useAuth0 } from "@auth0/auth0-react";

const AccessUnavailablePage = () => {
  const { user, logout } = useAuth0();

  return (
    <>
      <Global
        styles={{
          body: {
            margin: 0,
            padding: 0,
            fontFamily: `"Poppins", sans-serif`,
            background: "linear-gradient(135deg, #0f172a 0%, #1e293b 100%)",
            color: "#f8fafc",
          },
        }}
      />
      <PageContainer>
        <ContentWrapper>
          <Title>Access Unavailable</Title>
          <Message>
            Hey {user?.email || "there"}, it looks like you don't currently have
            access to the Library.
          </Message>
          <Message>
            To unlock full access and start discovering validated product ideas,
            please order now
          </Message>
          <Actions>
            <ActionButton href="https://www.willpayforthis.com/preorder">
              Purchase
            </ActionButton>
            <SecondaryButton onClick={logout}>Logout</SecondaryButton>
          </Actions>
          <SupportMessage>
            If you already ordered and are unable to access the Library, please
            reach out to{" "}
            <a href="mailto:willpayforthis@gmail.com">
              willpayforthis@gmail.com
            </a>{" "}
            for help.
          </SupportMessage>
          <Divider />
          <VideoSection>
            <SectionTitle>
              <Star size={24} />
              Check Out Our Quick Demo
            </SectionTitle>
            <DemoDescription>
              This short video shows you how WillPayForThis can help you find
              validated product ideas on Twitter.
            </DemoDescription>
            <Video controls>
              <source src="/demo-2.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </Video>
          </VideoSection>
        </ContentWrapper>
      </PageContainer>
    </>
  );
};

export default AccessUnavailablePage;

/* -------------------------- Styled Components -------------------------- */
const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background: linear-gradient(135deg, #0f172a, #1e293b);
  overflow-y: auto;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  background: #1e293b;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  font-size: 1.25rem;
  margin-bottom: 1rem;
`;

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 1.5rem 0;
`;

const ActionButton = styled.a`
  background: #6366f1;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  text-decoration: none;
  font-weight: 600;
  transition: background 0.3s;
  &:hover {
    background: #4f46e5;
  }
`;

const SecondaryButton = styled.button`
  background: transparent;
  border: 2px solid #6366f1;
  color: #6366f1;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background: #6366f1;
    color: #fff;
  }
`;

const SupportMessage = styled.p`
  font-size: 1rem;
  margin: 1rem 0 2rem;
  a {
    color: #6366f1;
    text-decoration: underline;
    &:hover {
      color: #8b5cf6;
    }
  }
`;

const Divider = styled.hr`
  margin: 2rem 0;
  border: none;
  height: 1px;
  background: #334155;
`;

const VideoSection = styled.section`
  text-align: left;
`;

const SectionTitle = styled.h2`
  display: flex;
  align-items: center;
  font-size: 1.75rem;
  margin-bottom: 1rem;
  svg {
    margin-right: 0.5rem;
  }
`;

const DemoDescription = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const Video = styled.video`
  width: 100%;
  max-height: 450px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;
