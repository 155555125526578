// AdminPage.js
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useReducer,
} from "react";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";
import { Search, Filter, DollarSign, LogOut } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Masonry from "react-masonry-css";

import { Tweet } from "../components/TweetComponent";
import { CustomDropdown } from "../components/CustomDropdown";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { adminUpdateStatus, adminLoadTweets } from "../functions/apiFunctions";

const PAGE_SIZE = 3;

// A simple debounce helper (same as used in Library.js)
const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
      timer = null;
    }, delay);
  };
};

// Reducer to manage the tweets list
const tweetsReducer = (state, action) => {
  switch (action.type) {
    case "SET_TWEETS":
      return { ...state, allTweets: action.payload };
    case "REMOVE_TWEET":
      return {
        ...state,
        allTweets: state.allTweets.filter(
          (tweet) => tweet.id !== action.payload
        ),
      };
    case "ADD_TWEET":
      return { ...state, allTweets: [action.payload, ...state.allTweets] };
    default:
      return state;
  }
};

// A simple notification component
const Notification = ({ message, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return <NotificationContainer type={type}>{message}</NotificationContainer>;
};

const AdminPage = () => {
  const { logout, isAuthenticated, getAccessTokenSilently, isLoading, user } =
    useAuth0();

  const [state, dispatch] = useReducer(tweetsReducer, { allTweets: [] });
  const { allTweets } = state;

  const [displayedTweets, setDisplayedTweets] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [loading, setLoading] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [hasAccess, setHasAccess] = useState(null);
  const [accessLoading, setAccessLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [notification, setNotification] = useState(null);

  const navigate = useNavigate();
  const containerRef = useRef(null);
  const searchBarRef = useRef(null);
  const loadMoreRef = useRef(null);

  // Only allow the designated admin email
  const isAdmin = isAuthenticated && user?.email === "josh@bickett.net";

  // When the search term changes
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Load admin tweets from your API endpoint
  const performLoadAdminTweets = async () => {
    try {
      if (!isAuthenticated) {
        setHasAccess(false);
        setAccessLoading(false);
        return;
      }
      const accessToken = await getAccessTokenSilently();
      const data = await adminLoadTweets(accessToken);
      console.log("Admin tweets data", data);
      if (data.access) {
        dispatch({ type: "SET_TWEETS", payload: data.tweets });
        setHasAccess(true);
      } else {
        setHasAccess(false);
      }
      setAccessLoading(false);
    } catch (err) {
      console.error("Error loading admin tweets:", err);
      setHasAccess(false);
      setAccessLoading(false);
      setNotification({
        message: "Failed to load admin tweets.",
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      performLoadAdminTweets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  // Build unique categories from the tweets
  useEffect(() => {
    const categories = Array.from(
      new Set(allTweets.filter((t) => t.category).map((t) => t.category))
    ).sort((a, b) => a.localeCompare(b));
    setUniqueCategories(categories);
  }, [allTweets]);

  // Filter tweets when search term or filters change
  useEffect(() => {
    const term = (searchTerm || "").toLowerCase();
    const filtered = allTweets.filter((tweet) => {
      const matchesContent = term
        ? tweet.content?.toLowerCase().includes(term)
        : true;
      const matchesCategory = selectedCategory
        ? tweet.category?.toLowerCase() === selectedCategory.toLowerCase()
        : true;
      const matchesType =
        selectedType === "" ? true : tweet.type === Number(selectedType);
      return matchesContent && matchesCategory && matchesType;
    });
    setDisplayedTweets(filtered);
    setLimit(PAGE_SIZE);
  }, [searchTerm, selectedCategory, selectedType, allTweets]);

  // Intersection observer to load more tweets
  const handleIntersection = useCallback(
    debounce((entry) => {
      if (entry.isIntersecting && !loading) {
        setLoading(true);
        setTimeout(() => {
          setLimit((prev) => prev + PAGE_SIZE);
          setLoading(false);
        }, 250);
      }
    }, 600),
    [loading]
  );

  useEffect(() => {
    if (!containerRef.current) return;
    if (limit >= displayedTweets.length) return;
    const observerOptions = {
      root: containerRef.current,
      threshold: 0.5,
      rootMargin: "0px 0px 100px 0px",
    };
    const observer = new IntersectionObserver(([entry]) => {
      handleIntersection(entry);
    }, observerOptions);
    if (loadMoreRef.current) observer.observe(loadMoreRef.current);
    return () => {
      if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
    };
  }, [limit, displayedTweets, handleIntersection]);

  // Back-to-top button visibility
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const handleScroll = () => {
      setShowBackToTop(container.scrollTop > 300);
    };
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    containerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleLogoClick = () => navigate("/");
  const handleLogout = () => logout({ returnTo: window.location.origin });

  // Handler for admin approval/rejection actions.
  const handleAdminUpdateStatus = async (tweetId, status) => {
    const tweetToUpdate = allTweets.find((tweet) => tweet.id === tweetId);
    if (!tweetToUpdate) return;

    // Optimistically remove the tweet
    dispatch({ type: "REMOVE_TWEET", payload: tweetId });

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await adminUpdateStatus(accessToken, tweetId, status);
      console.log("Admin update status response", response);
      setNotification({
        message: `Tweet has been ${
          status === "admin-approved" ? "approved" : "rejected"
        }.`,
        type: "success",
      });
    } catch (error) {
      console.error("Error updating tweet status:", error);
      // Rollback if necessary:
      dispatch({ type: "ADD_TWEET", payload: tweetToUpdate });
      setNotification({
        message: `Failed to ${
          status === "admin-approved" ? "approve" : "reject"
        } the tweet.`,
        type: "error",
      });
    }
  };

  // Options for filters
  const categoryOptions = ["All Categories", ...uniqueCategories];
  const typeOptions = [
    { value: "", label: "All Types" },
    { value: "1", label: "Would pay for product" },
    { value: "2", label: "General Product Request" },
    { value: "3", label: "Community Engagement" },
    { value: "4", label: "Market Research Insight" },
    { value: "5", label: "Pain Point Description" },
    { value: "6", label: "Partnership Opportunity" },
    { value: "7", label: "Success Story/Proof of Concept" },
  ];

  // Paginate tweets
  const paginatedTweets = displayedTweets.slice(0, limit);

  if (accessLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <LoadingText>Loading Tweets...</LoadingText>
      </LoadingContainer>
    );
  }

  if (!hasAccess || !isAdmin) {
    return (
      <>
        <Global
          styles={css`
            /* Global styles */
          `}
        />
        <AccessDeniedContainer>
          <AccessDeniedMessage>
            Access not available. Only administrators can access this page.
          </AccessDeniedMessage>
        </AccessDeniedContainer>
      </>
    );
  }

  // Masonry breakpoint settings (feel free to adjust)
  const breakpointColumnsObj = {
    default: 3,
    768: 1,
  };

  return (
    <>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
          body {
            margin: 0;
            padding: 0;
            font-family: "Poppins", sans-serif;
            background: #0f172a;
            color: #f8fafc;
          }
        `}
      />
      <Container ref={containerRef}>
        <Header>
          <LogoWrapper onClick={handleLogoClick}>
            <DollarSign size={32} />
            <LogoText>willpayforthis</LogoText>
            <BetaBadge>Beta</BetaBadge>
          </LogoWrapper>
          <LogoutButton onClick={handleLogout} aria-label="Logout">
            <LogOut size={16} style={{ marginRight: "0.3rem" }} />
            <LogoutText>Logout</LogoutText>
          </LogoutButton>
        </Header>

        <MainContent>
          <SearchBar ref={searchBarRef}>
            <SearchIcon>
              <Search size={20} />
            </SearchIcon>
            <SearchInput
              type="text"
              placeholder="Search by content..."
              value={searchTerm}
              onChange={handleSearchChange}
              aria-label="Search Tweets"
            />
            <CategoryContainer>
              <CustomDropdown
                options={categoryOptions}
                value={selectedCategory || "All Categories"}
                onChange={(option) =>
                  setSelectedCategory(option === "All Categories" ? "" : option)
                }
                placeholder="All Categories"
              />
            </CategoryContainer>
            <DesktopTypeContainer>
              <CustomDropdown
                options={typeOptions.map((type) => type.label)}
                value={
                  typeOptions.find((type) => type.value === selectedType)
                    ?.label || "All Types"
                }
                onChange={(selectedLabel) => {
                  const selected = typeOptions.find(
                    (type) => type.label === selectedLabel
                  );
                  setSelectedType(selected ? selected.value : "");
                }}
                placeholder="All Types"
              />
            </DesktopTypeContainer>
            <RecordsCount aria-live="polite">
              {displayedTweets.length} post
              {displayedTweets.length !== 1 ? "s" : ""}
            </RecordsCount>
            <FilterButton aria-label="Filter Tweets">
              <Filter size={20} />
            </FilterButton>
          </SearchBar>

          <MobileTypeContainer>
            <CustomDropdown
              options={typeOptions.map((type) => type.label)}
              value={
                typeOptions.find((type) => type.value === selectedType)
                  ?.label || "All Types"
              }
              onChange={(selectedLabel) => {
                const selected = typeOptions.find(
                  (type) => type.label === selectedLabel
                );
                setSelectedType(selected ? selected.value : "");
              }}
              placeholder="All Types"
            />
          </MobileTypeContainer>

          {paginatedTweets.map((tweet) => (
            <AdminTweetCard key={tweet.id}>
              <ButtonRow>
                <ApproveButton
                  onClick={() =>
                    handleAdminUpdateStatus(tweet.id, "admin-approved")
                  }
                  aria-label={`Approve tweet ${tweet.id}`}
                  disabled={loading}
                >
                  Approve
                </ApproveButton>
                <RejectButton
                  onClick={() =>
                    handleAdminUpdateStatus(tweet.id, "admin-rejected")
                  }
                  aria-label={`Reject tweet ${tweet.id}`}
                  disabled={loading}
                >
                  Reject
                </RejectButton>
              </ButtonRow>
              <TweetCardContent>
                <Tweet tweet={tweet} />
              </TweetCardContent>
            </AdminTweetCard>
          ))}

          {limit < displayedTweets.length && (
            <LoadMoreMarker ref={loadMoreRef}>
              {loading ? (
                <Spinner />
              ) : (
                <LoadingText>Scroll to load</LoadingText>
              )}
            </LoadMoreMarker>
          )}

          {notification && (
            <Notification
              message={notification.message}
              type={notification.type}
              onClose={() => setNotification(null)}
            />
          )}
        </MainContent>

        <BackToTopButton onClick={scrollToTop} aria-label="Back to Top">
          ↑ Top
        </BackToTopButton>

        <Footer>
          <FooterContent>
            <FooterLogo>
              <DollarSign size={24} />
              <LogoText>willpayforthis</LogoText>
            </FooterLogo>
            <FooterLinks>
              <FooterLink href="https://x.com/josh_bickett/">
                Message me about questions or provide input
              </FooterLink>
            </FooterLinks>
          </FooterContent>
          <FooterBottom>
            <Copyright>
              &copy; 2025 WillPayForThis. All rights reserved.
            </Copyright>
          </FooterBottom>
        </Footer>
      </Container>
    </>
  );
};

/* ------------------------- Styled Components ------------------------- */

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  position: relative;
`;

const Header = styled.header`
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  background: #4b5563;
  color: #ffffff;
  border: none;
  border-radius: 9999px;
  padding: 0.5rem 0.6rem;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background 0.2s, transform 0.2s;
  &:hover {
    background: #6b7280;
    transform: translateY(-1px);
  }
  &:active {
    transform: translateY(0);
  }
`;

const LogoutText = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;

const BetaBadge = styled.span`
  margin-left: 0.75rem;
  background: #ec4899;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.2rem 0.6rem;
  border-radius: 9999px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: 0.5rem;
`;

const MainContent = styled.main`
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 2rem;
`;

const SearchBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const SearchIcon = styled.span`
  color: #94a3b8;
`;

const SearchInput = styled.input`
  flex: 1 1 auto;
  min-width: 0;
  background: transparent;
  border: none;
  color: #f8fafc;
  font-size: 1rem;
  outline: none;
  &::placeholder {
    color: #94a3b8;
  }
`;

const CategoryContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const DesktopTypeContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

const RecordsCount = styled.span`
  flex: 0 0 auto;
  color: #94a3b8;
  font-size: 0.9rem;
  @media (max-width: 768px) {
    margin-left: 0.5rem;
    font-size: 0.8rem;
  }
`;

const FilterButton = styled.button`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: #94a3b8;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.2s;
  &:hover {
    color: #6366f1;
  }
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const MobileTypeContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin-bottom: 1.5rem;
  }
`;

/* Masonry Grid Styles */
const StyledMasonry = styled(Masonry)`
  display: flex;
  margin-left: -20px;
  width: auto;
  & .my-masonry-grid_column {
    padding-left: 20px;
    background-clip: padding-box;
  }
`;

const AdminTweetCard = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  min-height: 400px;
  padding: 10px;
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.2s;
  margin: 0 0 20px;
  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  }
`;

const ButtonRow = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  gap: 0.5rem;
  z-index: 10;
`;

const ApproveButton = styled.button`
  background: #10b981;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0.4rem 0.6rem;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  transition: background 0.2s;
  &:hover {
    background: #059669;
  }
  &:active {
    transform: translateY(1px);
  }
  &:disabled {
    background: #a5b4fc;
    cursor: not-allowed;
  }
`;

const RejectButton = styled.button`
  background: #f87171;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0.4rem 0.6rem;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  transition: background 0.2s;
  &:hover {
    background: #ef4444;
  }
  &:active {
    transform: translateY(1px);
  }
  &:disabled {
    background: #fca5a5;
    cursor: not-allowed;
  }
`;

const TweetCardContent = styled.div`
  padding-top: 2rem; /* leave space for the buttons */
`;

const LoadMoreMarker = styled.div`
  width: 100%;
  padding: 1rem;
  text-align: center;
`;

const LoadingText = styled.span`
  color: #94a3b8;
  font-size: 0.9rem;
`;

const Spinner = styled.div`
  width: 32px;
  height: 32px;
  margin: 0 auto;
  border: 4px solid #94a3b8;
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999;
  background: #6366f1;
  color: #ffffff;
  border: none;
  border-radius: 9999px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.2s;
  &:hover {
    background: #4f46e5;
  }
`;

const Footer = styled.footer`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem 0;
`;
const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;
const FooterLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const FooterLinks = styled.div`
  display: flex;
  gap: 1.5rem;
`;
const FooterLink = styled.a`
  color: #94a3b8;
  text-decoration: none;
  transition: color 0.2s;
  &:hover {
    color: #6366f1;
  }
`;
const FooterBottom = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;
const Copyright = styled.p`
  color: #64748b;
  font-size: 0.875rem;
  text-align: center;
`;

const AccessDeniedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #0f172a;
  padding: 2rem;
  text-align: center;
`;
const AccessDeniedMessage = styled.div`
  color: #f87171;
  font-size: 1.5rem;
  max-width: 600px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
const LoadingSpinner = styled.div`
  width: 48px;
  height: 48px;
  border: 6px solid #94a3b8;
  border-top-color: #6366f1;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const NotificationContainer = styled.div`
  position: fixed;
  top: 1rem;
  right: 1rem;
  background: ${({ type }) =>
    type === "success" ? "#10b981" : type === "error" ? "#f87171" : "#3b82f6"};
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out, fadeOut 0.3s ease-in-out 2.7s;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
`;

export default withAuthenticationRequired(AdminPage);
