// src/pages/LandingPage_V2.js
import { useState, useRef } from "react";
import styled from "@emotion/styled";
import {
  Star,
  DollarSign,
  Bell,
  Check,
  CheckCircle,
  ChevronDown,
} from "lucide-react";
import { css, Global, keyframes } from "@emotion/react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// NEW: Import the Auth0 hook
import { useAuth0 } from "@auth0/auth0-react";
import { motion } from "framer-motion";

// NEW: Import Tippy for tooltips
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import {
  createCheckOutSesssion,
  saveEmail,
} from "../../functions/apiFunctions";
import { Tweet } from "../../components/TweetContainer";
import { Footer } from "../../components/Footer";
import Masonry from "react-masonry-css";

// Sample tweet data
const sampleTweets = [
  { id: "1884784504511795249" },
  { id: "1866513178353451435" },
  { id: "1713718180936659110" },
];

/* Keyframes for animations */
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

// Bounce animation for the down arrow
const bounce = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

/* Styled components */

// This Container is now our scrollable container.
const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
`;

// NEW: Top-right button for authenticated users
const TopRightButton = styled(Link)`
  position: fixed;
  top: 20px;
  right: 20px;
  background: #6366f1;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  z-index: 1000;

  &:hover {
    background: #4f46e5;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }
`;

const Hero = styled.section`
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  overflow: hidden;
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #1e293b 0%, #0f172a 100%);
  z-index: -1;

  &::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
    background: radial-gradient(
      circle at center,
      rgba(99, 102, 241, 0.15) 0%,
      rgba(15, 23, 42, 0) 70%
    );
    animation: ${float} 15s ease-in-out infinite;
  }
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  animation: ${fadeIn} 1s ease-out;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: 0.5rem;
`;

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const GradientText = styled.span`
  background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const HeroSubtitle = styled.p`
  font-size: 1.75rem;
  color: #94a3b8;
  margin-bottom: 2rem;
  max-width: 600px;
  margin: 0 auto;
`;

/* ---------------- NEW TESTIMONIAL SECTION ---------------- */
// Turn the card into an <a> tag so it’s fully clickable.
const TestimonialCard = styled.a`
  display: block;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  padding: 1.5rem;
  flex: 1 1 300px;
  max-width: 350px;
  text-align: left;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  color: inherit; /* So text remains white */
  text-decoration: none;

  &:hover {
    transform: translateY(-4px);
  }

  &::before {
    content: "“";
    position: absolute;
    top: -10px;
    left: -5px;
    font-size: 4rem;
    color: #6366f1;
    opacity: 0.2;
    pointer-events: none;
  }
  &::after {
    content: "”";
    position: absolute;
    bottom: -30px;
    right: 10px;
    font-size: 4rem;
    color: #6366f1;
    opacity: 0.2;
    pointer-events: none;
  }
`;

const TestimonialSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const TestimonialText = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #f8fafc;
  margin: 0;
`;
/* --------------------------------------------------------- */

const OrderButton = styled.button`
  background: #6366f1;
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 2rem;

  &:hover {
    background: #4f46e5;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }
`;

const ErrorMessage = styled.p`
  color: #f87171;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: center;
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #10b981;
  margin-top: 1rem;
  font-weight: 600;
`;

const ExploreSection = styled.section`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;
`;

const SectionTitle = styled.h2`
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const StayUpdatedTitle = styled.h2`
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
`;

const FeaturesSection = styled.section`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const FeatureCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const FeatureIcon = styled.div`
  background: rgba(99, 102, 241, 0.1);
  color: #6366f1;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const FeatureDescription = styled.p`
  color: #94a3b8;
  font-size: 0.875rem;
`;

const VideoSection = styled.section`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;
`;

const DemoDescription = styled.p`
  color: #94a3b8;
  font-size: 1rem;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const Video = styled.video`
  width: 100%;
  max-width: 100%;
  border-radius: 0.5rem;
  margin-top: 2rem;
`;

const EmailSignupSection = styled.section`
  max-width: 800px;
  margin: 4rem auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
`;

const EmailSignupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    color: #94a3b8;
    margin-bottom: 1.5rem;
  }
`;

const SignupForm = styled.form`
  display: flex;
  max-width: 500px;
  margin: 0 auto;
  gap: 1rem;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

const SignupInput = styled.input`
  flex: 1;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 9999px;
  padding: 0.75rem 1.5rem;
  color: white;
  font-size: 1rem;
  outline: none;
  transition: box-shadow 0.3s ease;

  &::placeholder {
    color: #94a3b8;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5);
  }
`;

const SignupButton = styled.button`
  background: #6366f1;
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;

  &:hover {
    background: #4f46e5;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }
`;

const ConsentText = styled.p`
  font-size: 0.8rem;
  color: #a1a1aa;
  margin: 1rem 0;
`;

// NEW: Adjusted the arrow so that it sits higher (100px from the bottom)
const DownArrowWrapper = styled.div`
  position: absolute;
  bottom: 100px;
  left: 49%;
  transform: translateX(-50%);
  cursor: pointer;
  animation: ${bounce} 2s infinite;
  z-index: 1;

  @media (max-width: 768px) {
    left: 47%;
    bottom: 50px;
  }
`;

/* NEW: Monthly Subscription Card Styled Components */
const SubscriptionSection = styled.section`
  width: 400px; /* Fixed width for larger screens */
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 1.5rem;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 48px rgba(31, 38, 135, 0.25);
  }

  @media (max-width: 768px) {
    width: 80%; /* Adjust width on smaller screens */
    margin: 0 auto; /* Center the card */
  }
`;

const SubscriptionCardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SubscriptionTitle = styled.h2`
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const SubscriptionPrice = styled.p`
  font-size: 3rem;
  font-weight: 800;
  color: #10b981;
  margin-bottom: 1rem;
  position: relative;

  &::before {
    content: "$";
    position: absolute;
    font-size: 1.5rem;
    top: 0.5rem;
    left: -1rem;
  }
`;

const SubscriptionDescription = styled.p`
  font-size: 1.125rem;
  color: #94a3b8;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 300px;
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
  width: 100%;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  text-align: left;
  font-size: 1rem;
  color: #f8fafc;
  transition: all 0.3s ease;

  &:hover {
    transform: translateX(5px);
  }

  & svg {
    margin-right: 0.75rem;
    color: #10b981;
    flex-shrink: 0;
  }
`;

const SubscriptionOrderButton = styled(OrderButton)`
  margin-top: auto;
  padding: 0.75rem 2.5rem;
  font-size: 1.25rem;
  background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
  position: relative;
  overflow: hidden;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: all 0.5s;
  }

  &:hover::before {
    left: 100%;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const BetaBadge = styled.span`
  position: absolute;
  top: -10px;
  right: -10px;
  background: #ec4899;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 600;
  transform: rotate(15deg);
`;

const SubscriptionCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 1.5rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    margin: 2rem auto;
  }
`;

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15,
    },
  },
};

const featureVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 10,
    },
  },
};

const AnimatedSubscriptionSection = motion(SubscriptionSection);
const AnimatedFeatureItem = motion(FeatureItem);

/* LandingPageV2 Component */
export const LandingPageA = ({ abTestCase }) => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");

  // Attach a ref to the scrollable container
  const containerRef = useRef(null);

  // NEW: Get the authentication status from Auth0
  const { isAuthenticated } = useAuth0();

  // Email validation regex
  const validateEmail = (email) => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    saveEmail(email);
    setError("");
    console.log("Email submitted:", email);
    setIsSubmitted(true);
    setEmail("");
  };

  const handleOrderClick = () => {
    console.log("order clicked with test=>", abTestCase);
    createCheckOutSesssion({ basic: false, abTestCase });
  };

  // NEW: Scroll the inner container (not window) by one viewport height
  const handleArrowClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.clientHeight,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
          body {
            margin: 0;
            padding: 0;
            font-family: "Poppins", sans-serif;
            background: #0f172a;
            color: #f8fafc;
          }
        `}
      />
      <Helmet>
        <title>WillPayForThis.com - Discover market demand</title>
        <meta
          name="description"
          content="Be the first to know when willpayforthis.com launches. Discover what people are willing to pay for and get exclusive access to in-demand products and services."
        />
        <link rel="canonical" href="https://www.willpayforthis.com/" />
      </Helmet>

      {/* NEW: Show the "Go to Library" button if the user is authenticated */}
      <TopRightButton to="/library">Sign In</TopRightButton>

      <Container ref={containerRef}>
        {/* HERO SECTION */}
        <Hero>
          <HeroContent>
            <LogoWrapper>
              <DollarSign size={48} />
              <LogoText>willpayforthis</LogoText>
            </LogoWrapper>

            <HeroTitle>
              Discover What People
              <GradientText> Will Pay For</GradientText>
            </HeroTitle>

            <HeroSubtitle>
              Your shortcut to market-validated ideas.
            </HeroSubtitle>

            {/* NEW Testimonials "Above the Fold" */}
            <TestimonialSection>
              <Tippy content="Click to go to source">
                <TestimonialCard
                  href="https://x.com/AlfredSimon/status/1891846817886736652"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TestimonialText>
                    My new hack is to use the tool willpayforthis.com. Gives me
                    great ideas and then I'll look into if there is any interest
                    at all or just a wild tweet. I already have 3 potential
                    tools on my list.
                  </TestimonialText>
                </TestimonialCard>
              </Tippy>

              <Tippy content="Click to go to source">
                <TestimonialCard
                  href="https://x.com/dalib0rg/status/1889605127410962617"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TestimonialText>
                    You can also check http://willpayforthis.com ... monitors X
                    for best product ideas. It's quite a cool product.
                  </TestimonialText>
                </TestimonialCard>
              </Tippy>
            </TestimonialSection>

            {error && <ErrorMessage>{error}</ErrorMessage>}
            {isSubmitted && (
              <SuccessMessage>
                <CheckCircle size={16} />
                Thank you! We'll notify you when we launch.
              </SuccessMessage>
            )}
          </HeroContent>

          {/* Down Arrow to signal scrolling */}
          <DownArrowWrapper onClick={handleArrowClick}>
            <ChevronDown size={32} color="#f8fafc" />
          </DownArrowWrapper>

          <HeroBackground />
        </Hero>

        {/* EXPLORE SECTION */}
        <ExploreSection>
          <SectionTitle>
            <Star size={24} />
            Sneak Peek: What People Want
          </SectionTitle>
          <StyledMasonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {sampleTweets.map((tweet) => (
              <Tweet key={tweet.id} tweet={tweet} />
            ))}
          </StyledMasonry>
        </ExploreSection>

        {/* FEATURES SECTION */}
        <FeaturesSection>
          <SectionTitle>
            <DollarSign size={24} />
            Why Join WillPayForThis?
          </SectionTitle>
          <FeatureGrid>
            <FeatureCard>
              <FeatureIcon>
                <DollarSign size={32} />
              </FeatureIcon>
              <FeatureTitle>How it works</FeatureTitle>
              <FeatureDescription>
                It's pretty simple. People are saying what they want online. We
                accumulate posts in one place and make it easy to explore.
              </FeatureDescription>
            </FeatureCard>
            <FeatureCard>
              <FeatureIcon>
                <Bell size={32} />
              </FeatureIcon>
              <FeatureTitle>Product Ideas</FeatureTitle>
              <FeatureDescription>
                Hundreds of tweets from people talking about products they'd pay
                for.
              </FeatureDescription>
            </FeatureCard>
            <FeatureCard>
              <FeatureIcon>
                <Star size={32} />
              </FeatureIcon>
              <FeatureTitle>Twitter Research</FeatureTitle>
              <FeatureDescription>
                Search through curated tweets. Use it to validate an idea or
                come up with new products.
              </FeatureDescription>
            </FeatureCard>
          </FeatureGrid>
        </FeaturesSection>

        {/* VIDEO DEMO SECTION */}
        <VideoSection>
          <SectionTitle>
            <Star size={24} />
            Check Out Our Quick Demo
          </SectionTitle>
          <DemoDescription>
            This short video shows you how WillPayForThis can help you find
            validated product ideas on Twitter.
          </DemoDescription>
          <Video controls>
            <source src="/demo-2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
        </VideoSection>

        {/* MONTHLY SUBSCRIPTION CARDS SECTION */}
        <SubscriptionCardsContainer>
          {/* Basic Plan Card */}
          <AnimatedSubscriptionSection
            variants={cardVariants}
            initial="hidden"
            animate="visible"
          >
            <SubscriptionCardContent>
              <SubscriptionTitle>Lifetime Access</SubscriptionTitle>
              <SubscriptionPrice>30</SubscriptionPrice>
              <SubscriptionDescription>
                Find hundreds of unique ideas
              </SubscriptionDescription>
              <FeaturesList>
                <AnimatedFeatureItem variants={featureVariants}>
                  <Check size={20} />
                  Access to WillPayForThis tweet database
                </AnimatedFeatureItem>
                <AnimatedFeatureItem variants={featureVariants}>
                  <Check size={20} />
                  Monthly updates with new tweets
                </AnimatedFeatureItem>
                <AnimatedFeatureItem variants={featureVariants}>
                  <Check size={20} />
                  Unlimited searches
                </AnimatedFeatureItem>
                <AnimatedFeatureItem variants={featureVariants}>
                  <Check size={20} />
                  Save your favorites
                </AnimatedFeatureItem>
              </FeaturesList>
            </SubscriptionCardContent>
            <SubscriptionOrderButton onClick={handleOrderClick}>
              Get Started
            </SubscriptionOrderButton>
          </AnimatedSubscriptionSection>
        </SubscriptionCardsContainer>

        {/* EMAIL SIGNUP SECTION */}
        <EmailSignupSection>
          <StayUpdatedTitle>
            <Bell size={24} />
            Stay Updated
          </StayUpdatedTitle>
          <EmailSignupContent>
            <p>
              Be the first to know when we launch and get exclusive early
              access.
            </p>
            <SignupForm onSubmit={handleSubmit}>
              <SignupInput
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <SignupButton type="submit">
                {isSubmitted ? <CheckCircle size={20} /> : "Notify Me"}
              </SignupButton>
            </SignupForm>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {isSubmitted && (
              <SuccessMessage>
                <CheckCircle size={16} />
                Thank you! We'll notify you when we launch.
              </SuccessMessage>
            )}
          </EmailSignupContent>
          <ConsentText>
            By entering your email, you agree to receive updates and newsletters
            from willpayforthis@gmail.com.
          </ConsentText>
        </EmailSignupSection>

        {/* FOOTER */}
        <Footer />
      </Container>
    </>
  );
};

// Masonry Breakpoints
const breakpointColumnsObj = {
  default: 3,
  768: 1,
};

// Masonry Styled Component
const StyledMasonry = styled(Masonry)`
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;

  & .my-masonry-grid_column {
    padding-left: 20px; /* gutter size */
    background-clip: padding-box;
  }
`;

export default LandingPageA;
