// Library.js
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";
// Updated icon: using Star instead of Check for saving.
import { Search, Filter, Star, X, ExternalLink, Twitter } from "lucide-react";
import Tippy from "@tippyjs/react"; // Tippy for tooltips
import "tippy.js/dist/tippy.css"; // Tippy styles
import { useNavigate } from "react-router-dom";

import { Tweet } from "../components/TweetComponent";
import { CustomDropdown } from "../components/CustomDropdown";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { loadTweets, setTweetStatus } from "../functions/apiFunctions";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import Masonry from "react-masonry-css";
import AccessUnavailablePage from "./AccessUnavailablePage";

const PAGE_SIZE = 50;

// Updated Status constants: only user-level statuses remain
const STATUS = {
  USER_APPROVED: "user-approved", // Tweets saved by the user
  USER_REJECTED: "user-rejected",
};

// Simple debounce helper for the intersection observer
const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
      timer = null;
    }, delay);
  };
};

const Library = () => {
  const { logout, isAuthenticated, getAccessTokenSilently, isLoading, user } =
    useAuth0();

  // View toggle state (either "library" or "saved")
  const [view, setView] = useState("library");

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [allTweets, setAllTweets] = useState([]);
  const [displayedTweets, setDisplayedTweets] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [loading, setLoading] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [hasAccess, setHasAccess] = useState(null);
  const [accessLoading, setAccessLoading] = useState(true);

  // New state to track API errors
  const [apiError, setApiError] = useState(null);

  // New state for userType
  const [userType, setUserType] = useState("");

  const navigate = useNavigate();
  const loadMoreRef = useRef(null);
  const containerRef = useRef(null);
  const searchBarRef = useRef(null);

  // Handle search input changes
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  // Load tweets from the API endpoint
  const performLoadTweets = async () => {
    try {
      if (!isAuthenticated) {
        setHasAccess(false);
        setAccessLoading(false);
        return;
      }
      const accessToken = await getAccessTokenSilently();
      const { access, tweets, user_type } = await loadTweets(accessToken);
      console.log("Tweets loaded:", tweets);
      setUserType(user_type);
      setHasAccess(access);
      if (access && tweets) {
        setAllTweets(tweets);
      }
      setAccessLoading(false);
    } catch (err) {
      console.error("Error during loading tweets:", err);
      // Set API error separately
      setApiError(
        err.message || "An unknown error occurred while loading tweets."
      );
      setAccessLoading(false);
    }
  };

  useEffect(() => {
    performLoadTweets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  // Build a list of unique categories from all tweets
  useEffect(() => {
    const categories = Array.from(
      new Set(
        allTweets
          .filter((tweet) => tweet.category)
          .map((tweet) => tweet.category)
      )
    ).sort((a, b) => a.localeCompare(b));
    setUniqueCategories(categories);
  }, [allTweets]);

  // Filter tweets when search or filter options change
  useEffect(() => {
    const term = (searchTerm || "").toLowerCase();
    const newFiltered = allTweets.filter((tweet) => {
      const matchesContent = term
        ? tweet.content?.toLowerCase().includes(term)
        : true;
      const matchesCategory = selectedCategory
        ? tweet.category?.toLowerCase() === selectedCategory.toLowerCase()
        : true;
      const matchesType =
        selectedType === "" ? true : tweet.type === Number(selectedType);
      return matchesContent && matchesCategory && matchesType;
    });
    setDisplayedTweets(newFiltered);
    setLimit(PAGE_SIZE);
  }, [searchTerm, selectedCategory, selectedType, allTweets]);

  // Separate tweet lists for library vs. saved
  // Library view shows tweets that have a null status.
  const libraryTweets = useMemo(
    () => displayedTweets.filter((tweet) => tweet.status === null),
    [displayedTweets]
  );
  // Saved view shows tweets that have been explicitly marked as user-approved.
  const savedTweets = useMemo(
    () =>
      displayedTweets.filter((tweet) => tweet.status === STATUS.USER_APPROVED),
    [displayedTweets]
  );

  // Determine which tweets to show based on current view
  const tweetsToShow = view === "library" ? libraryTweets : savedTweets;

  // Intersection observer to load more tweets when scrolling
  const handleIntersection = useCallback(
    debounce((entry) => {
      if (entry.isIntersecting && !loading) {
        setLoading(true);
        setTimeout(() => {
          setLimit((prevLimit) => prevLimit + PAGE_SIZE);
          setTimeout(() => setLoading(false), 1000);
        }, 250);
      }
    }, 600),
    [loading]
  );

  useEffect(() => {
    if (!containerRef.current) return;
    if (limit >= tweetsToShow.length) return;
    const observerOptions = {
      root: containerRef.current,
      threshold: 0.5,
      rootMargin: "0px 0px 100px 0px",
    };
    const observer = new IntersectionObserver(([entry]) => {
      handleIntersection(entry);
    }, observerOptions);
    if (loadMoreRef.current) observer.observe(loadMoreRef.current);
    return () => {
      if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
    };
  }, [limit, tweetsToShow, loading, handleIntersection]);

  // “Back to Top” button show/hide based on scroll position
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const handleScroll = () => {
      if (container.scrollTop > 300) setShowBackToTop(true);
      else setShowBackToTop(false);
    };
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    containerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Options for the category and type filters
  const categoryOptions = ["All Categories", ...uniqueCategories];
  const typeOptions = [
    { value: "", label: "All Types" },
    { value: "1", label: "Would pay for product" },
    { value: "2", label: "General Product Request" },
    { value: "3", label: "Community Engagement Opportunity" },
    { value: "4", label: "Market Research Insight" },
    { value: "5", label: "Pain Point Description" },
    { value: "6", label: "Partnership Opportunity" },
    { value: "7", label: "Success Story/Proof of Concept" },
  ];

  // Handler to update tweet status as approved (saved) or rejected.
  // This function optimistically updates the UI so that the tweet immediately moves (or hides) before the API call returns.
  const handleSetUserApproval = async (tweetId, userApproved) => {
    const newStatus = userApproved
      ? STATUS.USER_APPROVED
      : STATUS.USER_REJECTED;

    // Optimistically update the local state immediately.
    setAllTweets((prevTweets) =>
      prevTweets.map((tweet) =>
        tweet.id === tweetId ? { ...tweet, status: newStatus } : tweet
      )
    );

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await setTweetStatus(accessToken, tweetId, newStatus);
      if (!response) {
        console.error(
          "API call failed. Optionally, you can revert the optimistic update here."
        );
      }
    } catch (error) {
      console.error("Error updating tweet status:", error);
    }
  };

  // Paginate tweets based on the current view
  const paginatedTweets = tweetsToShow.slice(0, limit);

  // If we have an API error, show the error message.
  if (apiError) {
    return (
      <>
        <Global
          styles={css`
            body {
              margin: 0;
              padding: 0;
              font-family: "Poppins", sans-serif;
              background: #0f172a;
              color: #f8fafc;
            }
          `}
        />
        <ErrorContainer>
          <ErrorMessage>Error: {apiError}</ErrorMessage>
        </ErrorContainer>
      </>
    );
  }

  if (accessLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <LoadingText>Loading Tweets...</LoadingText>
      </LoadingContainer>
    );
  }

  if (!hasAccess) {
    return <AccessUnavailablePage />;
  }

  // Configure Masonry breakpoints
  const breakpointColumnsObj = {
    default: 3,
    768: 1,
  };

  return (
    <>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
          body {
            margin: 0;
            padding: 0;
            font-family: "Poppins", -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
              "Helvetica Neue", sans-serif;
            background: #0f172a;
            color: #f8fafc;
          }
        `}
      />
      <Container ref={containerRef}>
        <Header userType={userType} />
        <MainContent>
          {/* Search & Filter Bar */}
          <SearchBar ref={searchBarRef}>
            <SearchIcon>
              <Search size={20} />
            </SearchIcon>
            <SearchInput
              type="text"
              placeholder="Search by content..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            {/* Desktop category dropdown */}
            <CategoryContainer>
              <CustomDropdown
                options={categoryOptions}
                value={selectedCategory || "All Categories"}
                onChange={(option) =>
                  setSelectedCategory(option === "All Categories" ? "" : option)
                }
                placeholder="All Categories"
              />
            </CategoryContainer>
            <DesktopTypeContainer>
              <CustomDropdown
                options={typeOptions.map((type) => type.label)}
                value={
                  typeOptions.find((type) => type.value === selectedType)
                    ?.label || "All Types"
                }
                onChange={(selectedLabel) => {
                  const selected = typeOptions.find(
                    (type) => type.label === selectedLabel
                  );
                  setSelectedType(selected ? selected.value : "");
                }}
                placeholder="All Types"
              />
            </DesktopTypeContainer>
            <RecordsCount aria-live="polite">
              {displayedTweets.length} post
              {displayedTweets.length !== 1 ? "s" : ""}
            </RecordsCount>
            {/* Filter "button" is now a non-clickable div */}
            <FilterIcon aria-label="Filter Tweets">
              <Filter size={20} />
            </FilterIcon>
          </SearchBar>

          {/* Mobile-only filters: show both Tweet Type and Category dropdowns side by side */}
          <MobileFiltersContainer>
            <CustomDropdown
              options={typeOptions.map((type) => type.label)}
              value={
                typeOptions.find((type) => type.value === selectedType)
                  ?.label || "All Types"
              }
              onChange={(selectedLabel) => {
                const selected = typeOptions.find(
                  (type) => type.label === selectedLabel
                );
                setSelectedType(selected ? selected.value : "");
              }}
              placeholder="All Types"
            />
            <CustomDropdown
              options={categoryOptions}
              value={selectedCategory || "All Categories"}
              onChange={(option) =>
                setSelectedCategory(option === "All Categories" ? "" : option)
              }
              placeholder="All Categories"
            />
          </MobileFiltersContainer>

          {/* Always show the view toggle */}
          <ViewToggleContainer>
            <ToggleButton
              active={view === "library"}
              onClick={() => setView("library")}
            >
              Library
            </ToggleButton>
            <ToggleButton
              active={view === "saved"}
              onClick={() => setView("saved")}
            >
              Saved
            </ToggleButton>
          </ViewToggleContainer>

          {/* Masonry grid */}
          <StyledMasonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {paginatedTweets.map((tweet) => (
              <TweetCard key={tweet.id}>
                {view === "library" && (
                  <ButtonRow className="button-row">
                    <Tippy content="Save tweet">
                      <SaveButton
                        onClick={() => handleSetUserApproval(tweet.id, true)}
                        aria-label={`Save tweet ${tweet.id}`}
                      >
                        <Star size={16} />
                      </SaveButton>
                    </Tippy>
                    <Tippy content="Reject tweet">
                      <RejectButton
                        onClick={() => handleSetUserApproval(tweet.id, false)}
                        aria-label={`Reject tweet ${tweet.id}`}
                      >
                        <X size={16} />
                      </RejectButton>
                    </Tippy>
                  </ButtonRow>
                )}
                <PostCenter>
                  <TweetContainer>
                    <Tweet tweet={tweet} />
                  </TweetContainer>
                </PostCenter>
              </TweetCard>
            ))}
          </StyledMasonry>

          {/* Show load-more spinner if there are more tweets to load */}
          {limit < tweetsToShow.length && (
            <LoadMoreMarker ref={loadMoreRef}>
              {loading ? (
                <Spinner />
              ) : (
                <LoadingText>Scroll to load</LoadingText>
              )}
            </LoadMoreMarker>
          )}

          {/**
           * When in "saved" view AND we've reached the end of the list,
           * show the CTA to share on Twitter.
           */}
          {view === "saved" &&
            limit >= tweetsToShow.length &&
            savedTweets.length > 0 && (
              <InspirationContainer>
                <InspirationHeading>
                  Did you find an idea that inspired you?
                </InspirationHeading>
                <ButtonGroup>
                  {/* Updated Twitter share link */}
                  <TwitterButton
                    href="https://twitter.com/intent/tweet?text=I%20found%20a%20great%20idea%20on%20willpayforthis.com.%20Check%20it%20out!"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Share on Twitter <Twitter size={16} />
                  </TwitterButton>
                </ButtonGroup>
              </InspirationContainer>
            )}
        </MainContent>

        {showBackToTop && (
          <BackToTopButton onClick={scrollToTop} aria-label="Back to Top">
            ↑ Top
          </BackToTopButton>
        )}
        <Footer />
      </Container>
    </>
  );
};

export default withAuthenticationRequired(Library);

/* ---------------------- Styled Components ---------------------- */

// Masonry grid wrapper
const StyledMasonry = styled(Masonry)`
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
  & .my-masonry-grid_column {
    padding-left: 20px; /* gutter size */
    background-clip: padding-box;
  }
`;

// Overall container
const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  position: relative;
`;

// Main content area
const MainContent = styled.main`
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 2rem;
`;

/* -------------------- Search & Filter Bar -------------------- */
const SearchBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const SearchIcon = styled.span`
  color: #94a3b8;
`;

const SearchInput = styled.input`
  flex: 1 1 auto;
  min-width: 0;
  background: transparent;
  border: none;
  color: #f8fafc;
  font-size: 1rem;
  outline: none;
  &::placeholder {
    color: #94a3b8;
  }
`;

// Desktop-only category dropdown container
const CategoryContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

// Desktop-only type dropdown container
const DesktopTypeContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

const RecordsCount = styled.span`
  flex: 0 0 auto;
  color: #94a3b8;
  font-size: 0.9rem;
  @media (max-width: 768px) {
    margin-left: 0.5rem;
    font-size: 0.8rem;
  }
`;

/* The FilterIcon is now a non-clickable div */
const FilterIcon = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  background: transparent;
  color: #94a3b8;
  font-size: 1rem;
  cursor: default;
  transition: color 0.2s;
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

/* Mobile-only filters container for both Tweet Type and Category */
const MobileFiltersContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }
`;

const ViewToggleContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  justify-content: center;
  @media (max-width: 480px) {
    gap: 0.5rem;
  }
`;

const ToggleButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  color: #ffffff;
  background: ${(props) => (props.active ? "#6366f1" : "#4b5563")};
  transition: background 0.2s;
  &:hover {
    background: ${(props) => (props.active ? "#4f46e5" : "#6b7280")};
  }
  @media (max-width: 480px) {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }
`;

/* ---------------------- Pagination & Load More ---------------------- */
const LoadMoreMarker = styled.div`
  width: 100%;
  padding: 1rem;
  text-align: center;
`;

const LoadingText = styled.span`
  color: #94a3b8;
  font-size: 0.9rem;
`;

const Spinner = styled.div`
  width: 32px;
  height: 32px;
  margin: 0 auto;
  border: 4px solid #94a3b8;
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

/* -------------------- Back To Top Button -------------------- */
const BackToTopButton = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999;
  background: #6366f1;
  color: #ffffff;
  border: none;
  border-radius: 9999px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.2s;
  &:hover {
    background: #4f46e5;
  }
`;

/* ------------------------ Access Denied ------------------------ */
const AccessDeniedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #0f172a;
  padding: 2rem;
  text-align: center;
`;

const AccessDeniedMessage = styled.div`
  color: #f87171;
  font-size: 1.5rem;
  max-width: 600px;
`;

const PreorderLink = styled.a`
  color: #6366f1;
  text-decoration: underline;
  &:hover {
    color: #8b5cf6;
  }
`;

/* ------------------------- Loading ------------------------- */
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LoadingSpinner = styled.div`
  width: 48px;
  height: 48px;
  border: 6px solid #94a3b8;
  border-top-color: #6366f1;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

/* --------------- New Tweet Card with Action Buttons --------------- */
const ButtonRow = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  /* On mobile devices, always show the buttons */
  @media (max-width: 768px) {
    opacity: 1;
  }
`;

// TweetCard reveals .button-row on hover.
const TweetCard = styled.div`
  border-radius: 8px;
  min-height: 200px;
  margin: 0.5rem; /* Added margin between cards */
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  word-wrap: break-word;
  transition: box-shadow 0.2s, transform 0.2s;
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
  }
  &:hover .button-row {
    opacity: 1;
  }
`;

// Updated SaveButton: gold/yellow background for the star.
const SaveButton = styled.button`
  background: #ffd700;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0.3rem 0.4rem; /* Adjusted for icon size */
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  &:hover {
    background: #ffc107;
  }
  &:active {
    transform: translateY(1px);
  }
`;

// Updated RejectButton: light gray background for the X.
const RejectButton = styled.button`
  background: #e5e7eb;
  color: #000000;
  border: none;
  border-radius: 4px;
  padding: 0.3rem 0.4rem; /* Adjusted for icon size */
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  &:hover {
    background: #d1d5db;
  }
  &:active {
    transform: translateY(1px);
  }
`;

const PostCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0rem;
`;

const TweetContainer = styled.div`
  width: 100%;
  max-width: 100%;
`;

/* -------------- “Did you find an idea...” CTA -------------- */
const InspirationContainer = styled.div`
  margin-top: 2rem;
  text-align: center;
`;

const InspirationHeading = styled.p`
  margin-bottom: 1rem;
  color: #f8fafc;
`;

const ButtonGroup = styled.div`
  display: inline-flex;
  gap: 1rem;
`;

const TypeformButton = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
  background: #2563eb;
  color: #fff;
  padding: 0.6rem 1rem;
  border-radius: 9999px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background: #1e3a8a;
  }
`;

const TwitterButton = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
  background: #1d9bf0;
  color: #fff;
  padding: 0.6rem 1rem;
  border-radius: 9999px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background: #0c7abf;
  }
`;

/* -------------------- Error Message Styles -------------------- */
const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #0f172a;
  padding: 2rem;
  text-align: center;
`;

const ErrorMessage = styled.div`
  color: #f87171;
  font-size: 1.5rem;
  max-width: 600px;
`;
