import { local, runLocalCode } from "./debug";

const baseUrl = local
  ? "http://127.0.0.1:5000/"
  : "https://main-backend-api-332ea47a673b.herokuapp.com/";

export const saveEmail = async (email) => {
  try {
    console.log("Attempting to save email:", email);
    const url = `${baseUrl}/wpft/api/save_email`;
    console.log("Request URL:", url);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    console.log("Response status:", response.status);
    if (!response.ok) {
      console.error("Failed to save email. Status:", response.status);
    } else {
      console.log("Email saved successfully.");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
export const createCheckOutSesssion = async ({ basic = false }) => {
  try {
    const url = `${baseUrl}/wpft/api/create_checkout_session`;
    const formData = new FormData();

    // We still append any other necessary fields
    formData.append("runLocalCode", runLocalCode);
    formData.append("basic", basic);

    // 1) Retrieve the entire metadata object from localStorage
    const metadataString = localStorage.getItem("metadata");
    if (!metadataString) {
      // If we somehow have no metadata, you can default it or handle accordingly
      console.warn("No metadata in localStorage");
    }

    // 2) Append the full metadata JSON as a string
    // (Your backend will need to parse this JSON string)
    formData.append("metadata", metadataString || "{}");

    // Now POST the formData to your backend
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    const session = await response.json();
    console.log("session", session);

    // Redirect or handle session link
    window.location.href = session.url;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const checkAccess = async (accessToken) => {
  const url = `${baseUrl}/wpft/api/check_access`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    return data.access;
  } catch (error) {
    console.error("Failed to check access:", error);
    return false;
  }
};

export const loadTweets = async (accessToken) => {
  const url = `${baseUrl}/wpft/api/load_tweets`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  // If response is not ok, attempt to extract and throw the error message from the JSON.
  if (!response.ok) {
    console.log("Reponse not ok", response);
    const errorData = await response.json();
    throw new Error(errorData.error || `Error: ${response.status}`);
  }

  const data = await response.json();
  return data; // Returns an object with { access: boolean, tweets: array }
};

export const loadTweetsFree = async () => {
  const url = `${baseUrl}/wpft/api/load_tweets_free`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  // If response is not ok, attempt to extract and throw the error message from the JSON.
  if (!response.ok) {
    console.log("Reponse not ok", response);
    const errorData = await response.json();
    throw new Error(errorData.error || `Error: ${response.status}`);
  }

  const data = await response.json();
  return data; // Returns an object with { access: boolean, tweets: array }
};

export const adminLoadTweets = async (accessToken) => {
  const url = `${baseUrl}/wpft/api/admin_load_tweets`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    return data; // Returns the data from the API
  } catch (error) {
    console.error("Failed to load admin tweets:", error);
    return null; // Return null in case of an error
  }
};

export const adminUpdateStatus = async (accessToken, tweetId, status) => {
  const url = `${baseUrl}/wpft/api/admin_update_status`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ tweetId, status }), // Send tweetId and status in the body
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    return true; // Indicate success
  } catch (error) {
    console.error("Failed to reject tweet:", error);
    return false; // Indicate failure
  }
};

export const loadPosts = async (accessToken) => {
  const url = `${baseUrl}/wpft/api/load_posts`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!res.ok) throw new Error(`Error: ${res.status}`);
    return await res.json(); // Expected: { access: true, posts: [{..., status: "ai-approved" }, ...] }
  } catch (error) {
    console.error("Failed to load posts:", error);
    return null;
  }
};

/**
 * Set user approval for a post.
 * @param {string} postId - The external post ID (e.g., Twitter ID).
 * @param {boolean} userApproved - True to approve, false to reject.
 */
export const setUserApproval = async (accessToken, postId, userApproved) => {
  const url = `${baseUrl}/wpft/api/set_approval`;
  // Map userApproved boolean to status string
  const status = userApproved ? "user-approved" : "user-rejected";

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ postId, status }),
    });
    if (!res.ok) throw new Error(`Error: ${res.status}`);
    const data = await res.json();
    if (data.success) {
      return true;
    } else {
      throw new Error(data.message || "Unknown error");
    }
  } catch (error) {
    console.error("Failed to set user approval:", error);
    return false;
  }
};

export const setStatus = async (accessToken, postId, status) => {
  const url = `${baseUrl}/wpft/api/set_status`;

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ postId, status }),
    });

    if (!res.ok) {
      const errorData = await res.json();
      throw new Error(errorData.error || `Error: ${res.status}`);
    }

    const data = await res.json();
    if (data.success) {
      return true;
    } else {
      throw new Error(data.message || "Unknown error");
    }
  } catch (error) {
    console.error("Failed to set status:", error);
    return false;
  }
};

export const setTweetStatus = async (accessToken, postId, status) => {
  const url = `${baseUrl}/wpft/api/set_tweet_status`;

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ postId, status }),
    });

    if (!res.ok) {
      const errorData = await res.json();
      throw new Error(errorData.error || `Error: ${res.status}`);
    }

    const data = await res.json();
    if (data.success) {
      return true;
    } else {
      throw new Error(data.message || "Unknown error");
    }
  } catch (error) {
    console.error("Failed to set status:", error);
    return false;
  }
};

export const getUserType = async (accessToken) => {
  const url = `${baseUrl}/wpft/api/get_user_type`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  // If response is not ok, attempt to extract and throw the error message from the JSON.
  if (!response.ok) {
    console.log("Response not ok", response);
    const errorData = await response.json();
    throw new Error(errorData.error || `Error: ${response.status}`);
  }

  const data = await response.json();
  return data; // Returns an object with { user_type: string }
};

export const cancelSubscription = async (accessToken) => {
  try {
    const url = `${baseUrl}/wpft/api/cancel_subscription`;
    const formData = new FormData();
    formData.append("runLocal", local); // assuming 'local' is defined globally

    // Retrieve your auth token from storage (adjust this as needed)

    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const result = await response.json();
    console.log("Subscription cancellation result:", result);
    return result;
  } catch (error) {
    console.error("Error canceling subscription:", error);
  }
};
