import React, { useState, useRef, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { css, Global, keyframes } from "@emotion/react";
import { Search, Filter, Star, X, Twitter } from "lucide-react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useNavigate, useLocation } from "react-router-dom";

import { Tweet } from "../components/TweetComponent";
import { CustomDropdown } from "../components/CustomDropdown";
import {
  createCheckOutSesssion,
  loadTweetsFree,
} from "../functions/apiFunctions";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import Masonry from "react-masonry-css";

// Set PAGE_SIZE to 6 so only 6 preview posts are shown.
const PAGE_SIZE = 9;

const STATUS = {
  USER_APPROVED: "user-approved",
  USER_REJECTED: "user-rejected",
};

const LibraryPreview = () => {
  const [view, setView] = useState("library");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [allTweets, setAllTweets] = useState([]);
  const [displayedTweets, setDisplayedTweets] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation(); // Grab URL parameters
  const containerRef = useRef(null);
  const searchBarRef = useRef(null);

  // Check if lifetime query param is true
  const isLifetime =
    new URLSearchParams(location.search).get("lifetime") === "true";

  const handleOrderClick = () => {
    createCheckOutSesssion({
      basic: !isLifetime,
      abTestCase: isLifetime ? "C" : "D",
    });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const performLoadTweets = async () => {
    setLoading(true);
    try {
      // Simulate a delay of 5 seconds
      const results = await loadTweetsFree();
      const tweets = results?.tweets;
      if (tweets) {
        setAllTweets(tweets);
      }
    } catch (err) {
      // handle error if needed
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    performLoadTweets();
  }, []);

  useEffect(() => {
    const categories = Array.from(
      new Set(
        allTweets
          .filter((tweet) => tweet.category)
          .map((tweet) => tweet.category)
      )
    ).sort((a, b) => a.localeCompare(b));
    setUniqueCategories(categories);
  }, [allTweets]);

  useEffect(() => {
    const term = (searchTerm || "").toLowerCase();
    const newFiltered = allTweets.filter((tweet) => {
      const matchesContent = term
        ? tweet.content?.toLowerCase().includes(term)
        : true;
      const matchesCategory = selectedCategory
        ? tweet.category?.toLowerCase() === selectedCategory.toLowerCase()
        : true;
      const matchesType =
        selectedType === "" ? true : tweet.type === Number(selectedType);
      return matchesContent && matchesCategory && matchesType;
    });
    setDisplayedTweets(newFiltered);
    // Reset preview limit back to 6 on filter change.
    setLimit(PAGE_SIZE);
  }, [searchTerm, selectedCategory, selectedType, allTweets]);

  // Separate tweet lists based on view
  const libraryTweets = useMemo(
    () => displayedTweets.filter((tweet) => tweet.status === null),
    [displayedTweets]
  );
  const savedTweets = useMemo(
    () =>
      displayedTweets.filter((tweet) => tweet.status === STATUS.USER_APPROVED),
    [displayedTweets]
  );

  const tweetsToShow = view === "library" ? libraryTweets : savedTweets;

  // Only show the first 6 preview posts
  const paginatedTweets = tweetsToShow.slice(0, limit);

  const breakpointColumnsObj = {
    default: 3,
    768: 1,
  };

  const handleSetUserApproval = (tweetId, userApproved) => {
    const newStatus = userApproved
      ? STATUS.USER_APPROVED
      : STATUS.USER_REJECTED;
    setAllTweets((prevTweets) =>
      prevTweets.map((tweet) =>
        tweet.id === tweetId ? { ...tweet, status: newStatus } : tweet
      )
    );
  };

  return (
    <>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
          body {
            margin: 0;
            padding: 0;
            font-family: "Poppins", -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
              "Helvetica Neue", sans-serif;
            background: #0f172a;
            color: #f8fafc;
          }
        `}
      />
      <Container ref={containerRef}>
        <Header userType={""} />
        <MainContent>
          {/* Search & Filter Bar */}
          <SearchBar ref={searchBarRef}>
            <SearchIcon>
              <Search size={20} />
            </SearchIcon>
            <SearchInput
              type="text"
              placeholder="Search by content..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <CategoryContainer>
              <CustomDropdown
                options={["All Categories", ...uniqueCategories]}
                value={selectedCategory || "All Categories"}
                onChange={(option) =>
                  setSelectedCategory(option === "All Categories" ? "" : option)
                }
                placeholder="All Categories"
              />
            </CategoryContainer>
            <DesktopTypeContainer>
              <CustomDropdown
                options={[
                  "All Types",
                  "Would pay for product",
                  "General Product Request",
                  "Community Engagement Opportunity",
                  "Market Research Insight",
                  "Pain Point Description",
                  "Partnership Opportunity",
                  "Success Story/Proof of Concept",
                ]}
                value={
                  selectedType
                    ? [
                        "Would pay for product",
                        "General Product Request",
                        "Community Engagement Opportunity",
                        "Market Research Insight",
                        "Pain Point Description",
                        "Partnership Opportunity",
                        "Success Story/Proof of Concept",
                      ][Number(selectedType) - 1]
                    : "All Types"
                }
                onChange={(selectedLabel) => {
                  const types = [
                    "Would pay for product",
                    "General Product Request",
                    "Community Engagement Opportunity",
                    "Market Research Insight",
                    "Pain Point Description",
                    "Partnership Opportunity",
                    "Success Story/Proof of Concept",
                  ];
                  const selectedIndex = types.indexOf(selectedLabel);
                  setSelectedType(
                    selectedIndex !== -1 ? String(selectedIndex + 1) : ""
                  );
                }}
                placeholder="All Types"
              />
            </DesktopTypeContainer>
            <RecordsCount aria-live="polite">
              {displayedTweets.length} post
              {displayedTweets.length !== 1 ? "s" : ""}
            </RecordsCount>
            <FilterIcon aria-label="Filter Tweets">
              <Filter size={20} />
            </FilterIcon>
          </SearchBar>

          {/* Mobile-only Filters */}
          <MobileFiltersContainer>
            <CustomDropdown
              options={[
                "All Types",
                "Would pay for product",
                "General Product Request",
                "Community Engagement Opportunity",
                "Market Research Insight",
                "Pain Point Description",
                "Partnership Opportunity",
                "Success Story/Proof of Concept",
              ]}
              value={
                selectedType
                  ? [
                      "Would pay for product",
                      "General Product Request",
                      "Community Engagement Opportunity",
                      "Market Research Insight",
                      "Pain Point Description",
                      "Partnership Opportunity",
                      "Success Story/Proof of Concept",
                    ][Number(selectedType) - 1]
                  : "All Types"
              }
              onChange={(selectedLabel) => {
                const types = [
                  "Would pay for product",
                  "General Product Request",
                  "Community Engagement Opportunity",
                  "Market Research Insight",
                  "Pain Point Description",
                  "Partnership Opportunity",
                  "Success Story/Proof of Concept",
                ];
                const selectedIndex = types.indexOf(selectedLabel);
                setSelectedType(
                  selectedIndex !== -1 ? String(selectedIndex + 1) : ""
                );
              }}
              placeholder="All Types"
            />
            <CustomDropdown
              options={["All Categories", ...uniqueCategories]}
              value={selectedCategory || "All Categories"}
              onChange={(option) =>
                setSelectedCategory(option === "All Categories" ? "" : option)
              }
              placeholder="All Categories"
            />
          </MobileFiltersContainer>

          {/* View Toggle */}
          <ViewToggleContainer>
            <ToggleButton
              active={view === "library"}
              onClick={() => setView("library")}
            >
              Library
            </ToggleButton>
            <ToggleButton
              active={view === "saved"}
              onClick={() => setView("saved")}
            >
              Saved
            </ToggleButton>
          </ViewToggleContainer>

          {/* Display Loading Spinner or Tweets */}
          {loading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <StyledMasonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {paginatedTweets.map((tweet) => (
                <TweetCard key={tweet.id}>
                  {view === "library" && (
                    <ButtonRow className="button-row">
                      <Tippy content="Save tweet">
                        <SaveButton
                          onClick={() => handleSetUserApproval(tweet.id, true)}
                          aria-label={`Save tweet ${tweet.id}`}
                        >
                          <Star size={16} />
                        </SaveButton>
                      </Tippy>
                      <Tippy content="Reject tweet">
                        <RejectButton
                          onClick={() => handleSetUserApproval(tweet.id, false)}
                          aria-label={`Reject tweet ${tweet.id}`}
                        >
                          <X size={16} />
                        </RejectButton>
                      </Tippy>
                    </ButtonRow>
                  )}
                  <PostCenter>
                    <TweetContainer>
                      <Tweet tweet={tweet} />
                    </TweetContainer>
                  </PostCenter>
                </TweetCard>
              ))}
            </StyledMasonry>
          )}

          <PreviewCTAContainer>
            <CTAHeading>All out of preview posts</CTAHeading>
            <CTADescription>
              {isLifetime
                ? "Order now to unlock access to over 500 ideas and discover your next project!"
                : "Subscribe now to unlock access to over 500 ideas and discover your next project!"}
            </CTADescription>
            <SubscribeButton onClick={handleOrderClick}>
              {isLifetime ? "Order Now" : "Subscribe Now"}
            </SubscribeButton>
          </PreviewCTAContainer>
        </MainContent>

        <Footer />
      </Container>
    </>
  );
};

export default LibraryPreview;

/* ---------------------- Styled Components ---------------------- */

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: ${spin} 1s linear infinite;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  margin: 2rem 0;
`;

const StyledMasonry = styled(Masonry)`
  display: flex;
  margin-left: -20px;
  width: auto;
  & .my-masonry-grid_column {
    padding-left: 20px;
    background-clip: padding-box;
  }
`;

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  position: relative;
`;

const MainContent = styled.main`
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 2rem;
`;

const SearchBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const SearchIcon = styled.span`
  color: #94a3b8;
`;

const SearchInput = styled.input`
  flex: 1 1 auto;
  min-width: 0;
  background: transparent;
  border: none;
  color: #f8fafc;
  font-size: 1rem;
  outline: none;
  &::placeholder {
    color: #94a3b8;
  }
`;

const CategoryContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const DesktopTypeContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

const RecordsCount = styled.span`
  flex: 0 0 auto;
  color: #94a3b8;
  font-size: 0.9rem;
  @media (max-width: 768px) {
    margin-left: 0.5rem;
    font-size: 0.8rem;
  }
`;

const FilterIcon = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  background: transparent;
  color: #94a3b8;
  font-size: 1rem;
  cursor: default;
  transition: color 0.2s;
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const MobileFiltersContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }
`;

const ViewToggleContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  justify-content: center;
  @media (max-width: 480px) {
    gap: 0.5rem;
  }
`;

const ToggleButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  color: #ffffff;
  background: ${(props) => (props.active ? "#6366f1" : "#4b5563")};
  transition: background 0.2s;
  &:hover {
    background: ${(props) => (props.active ? "#4f46e5" : "#6b7280")};
  }
  @media (max-width: 480px) {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }
`;

const ButtonRow = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  @media (max-width: 768px) {
    opacity: 1;
  }
`;

const TweetCard = styled.div`
  border-radius: 8px;
  min-height: 200px;
  margin: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  word-wrap: break-word;
  transition: box-shadow 0.2s, transform 0.2s;
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
  }
  &:hover .button-row {
    opacity: 1;
  }
`;

const SaveButton = styled.button`
  background: #ffd700;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0.3rem 0.4rem;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  &:hover {
    background: #ffc107;
  }
  &:active {
    transform: translateY(1px);
  }
`;

const RejectButton = styled.button`
  background: #e5e7eb;
  color: #000000;
  border: none;
  border-radius: 4px;
  padding: 0.3rem 0.4rem;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  &:hover {
    background: #d1d5db;
  }
  &:active {
    transform: translateY(1px);
  }
`;

const PostCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0rem;
`;

const TweetContainer = styled.div`
  width: 100%;
  max-width: 100%;
`;

const PreviewCTAContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  background: #1e293b;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
`;

const CTAHeading = styled.h2`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #f8fafc;
`;

const CTADescription = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1rem;
  color: #cbd5e1;
`;

const SubscribeButton = styled.button`
  background: #2563eb;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 9999px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s;
  &:hover {
    background: #1e3a8a;
  }
`;
