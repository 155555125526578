// components/Tweet.js
import React from "react";
import styled from "@emotion/styled";
import Tippy from "@tippyjs/react";
import he from "he";
import {
  MessageSquare,
  Repeat,
  Heart,
  Code,
  ShoppingCart,
  BookOpen,
  Film,
  DollarSign,
  Coffee,
  Lock,
  Home,
  Users,
  CreditCard,
  Smartphone,
  Compass,
  Globe,
  Cpu,
  PenTool,
  Shield,
  CheckSquare,
  User,
  Smile,
  Grid,
} from "lucide-react";

const TweetContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e1e8ed;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  max-width: 550px;
  margin-bottom: 20px;
  padding: 16px;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  color: #14171a;
  text-align: left;
  cursor: pointer;

  &:hover {
    .save-count {
      visibility: hidden;
    }
  }
`;

const TweetHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 12px;
  cursor: pointer;
`;

const TweetUserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Username = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #14171a;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const UserHandle = styled.span`
  color: #657786;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const SavesCount = styled.span`
  margin-left: auto;
  color: #657786;
  font-size: 14px;
  cursor: default;
  visibility: visible;
`;

const TweetContent = styled.div`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 12px;
  color: #14171a;
  text-align: left;
  white-space: pre-wrap;
`;

const TweetFooter = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #657786;
  border-top: 1px solid #e1e8ed;
  padding-top: 12px;
`;

const LeftFooter = styled.div`
  display: flex;
  align-items: center;
`;

const TweetDate = styled.span``;

const TweetStats = styled.div`
  display: flex;
  gap: 12px;
`;

const StatItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const BadgeContainer = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  margin-left: 8px;
  cursor: default;
`;

const categoryConfig = {
  "All Categories": {
    color: "#657786",
    backgroundColor: "#e1e8ed",
    icon: Grid,
  },
  "Ad-Free Services": {
    color: "#C62828",
    backgroundColor: "#FFEBEE",
    icon: Shield,
  },
  "AI & Analytics": {
    color: "#2E7D32",
    backgroundColor: "#E8F5E9",
    icon: Cpu,
  },
  "Design & Creativity": {
    color: "#1565C0",
    backgroundColor: "#E3F2FD",
    icon: PenTool,
  },
  "Development & Programming": {
    color: "#EF6C00",
    backgroundColor: "#FFF3E0",
    icon: Code,
  },
  "E-commerce & Retail": {
    color: "#6A1B9A",
    backgroundColor: "#F3E5F5",
    icon: ShoppingCart,
  },
  "Education & E-learning": {
    color: "#0277BD",
    backgroundColor: "#E1F5FE",
    icon: BookOpen,
  },
  "Entertainment & Media": {
    color: "#D84315",
    backgroundColor: "#FBE9E7",
    icon: Film,
  },
  "Finance & Fintech": {
    color: "#283593",
    backgroundColor: "#E8EAF6",
    icon: DollarSign,
  },
  "Food & Beverage": {
    color: "#F9A825",
    backgroundColor: "#FFFDE7",
    icon: Coffee,
  },
  "Health & Wellness": {
    color: "#388E3C",
    backgroundColor: "#E8F5E9",
    icon: Heart,
  },
  Lifestyle: {
    color: "#AD1457",
    backgroundColor: "#FCE4EC",
    icon: Smile,
  },
  "Personal Services": {
    color: "#00796B",
    backgroundColor: "#E0F7FA",
    icon: User,
  },
  "Privacy & Security": {
    color: "#6A1B9A",
    backgroundColor: "#F3E5F5",
    icon: Lock,
  },
  Productivity: {
    color: "#00796B",
    backgroundColor: "#E0F2F1",
    icon: CheckSquare,
  },
  "Real Estate": {
    color: "#33691E",
    backgroundColor: "#F1F8E9",
    icon: Home,
  },
  "Social Media & Networking": {
    color: "#37474F",
    backgroundColor: "#ECEFF1",
    icon: Users,
  },
  "Subscription Services": {
    color: "#827717",
    backgroundColor: "#F9FBE7",
    icon: CreditCard,
  },
  "Technology & Wearables": {
    color: "#0D47A1",
    backgroundColor: "#E3F2FD",
    icon: Smartphone,
  },
  "Travel & Hospitality": {
    color: "#EF6C00",
    backgroundColor: "#FFF3E0",
    icon: Compass,
  },
  "Web Tools": {
    color: "#424242",
    backgroundColor: "#F5F5F5",
    icon: Globe,
  },
};

export const Tweet = ({ tweet }) => {
  const {
    id,
    id_str,
    content,
    date,
    profileImage,
    likes = 0,
    replies = 0,
    retweets = 0,
    username = "Anonymous",
    user = {},
    screen_name = {},
    category,
    savedByOthersCount = 0,
  } = tweet;

  // Provide fallback image
  const defaultProfileImage =
    "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png";
  const imageUrl =
    profileImage || user.profile_image_url_https || defaultProfileImage;

  const displayName = user.name || username;
  const handle = screen_name;
  const tweetId = id_str || id;
  const tweetUrl = `https://twitter.com/i/web/status/${tweetId}`;
  const profileUrl = `https://twitter.com/${handle}`;
  const formattedDate = new Date(date).toLocaleDateString();

  // Decode HTML entities from the content
  const decodedContent = he.decode(content);

  // On container click, open tweet URL
  const handleTweetClick = () => {
    window.open(tweetUrl, "_blank", "noopener,noreferrer");
  };

  // On profile pic/name click, open user profile
  const handleProfileClick = (e) => {
    e.stopPropagation();
    window.open(profileUrl, "_blank", "noopener,noreferrer");
  };

  // Category icon
  const tweetCategory = category || "All Categories";
  const config =
    categoryConfig[tweetCategory] || categoryConfig["All Categories"];
  const CategoryIcon = config.icon;

  return (
    <TweetContainer onClick={handleTweetClick}>
      <TweetHeader>
        <ProfileImage
          src={imageUrl}
          alt={displayName}
          onClick={handleProfileClick}
        />
        <TweetUserInfo>
          <Username onClick={handleProfileClick}>{displayName}</Username>
          <UserHandle onClick={handleProfileClick}>@{handle}</UserHandle>
        </TweetUserInfo>

        {savedByOthersCount > 0 && (
          <SavesCount className="save-count">
            {savedByOthersCount} {savedByOthersCount === 1 ? "save" : "saves"}
          </SavesCount>
        )}
      </TweetHeader>

      <TweetContent>{decodedContent}</TweetContent>

      <TweetFooter>
        <LeftFooter>
          <TweetDate>{formattedDate}</TweetDate>
          <Tippy content={tweetCategory}>
            <BadgeContainer
              backgroundColor={config.backgroundColor}
              color={config.color}
            >
              <CategoryIcon size={16} />
            </BadgeContainer>
          </Tippy>
        </LeftFooter>
        <TweetStats>
          <StatItem>
            {replies}{" "}
            <MessageSquare
              size={16}
              color={replies >= 1 ? "#1da1f2" : "#657786"}
              fill={replies >= 1 ? "#1da1f2" : "none"}
            />
          </StatItem>
          <StatItem>
            {retweets}{" "}
            <Repeat
              size={16}
              color={retweets > 0 ? "#1da1f2" : "#657786"}
              fill="none"
            />
          </StatItem>
          <StatItem>
            {likes}{" "}
            <Heart
              size={16}
              color={likes > 0 ? "red" : "#657786"}
              fill={likes > 0 ? "red" : "none"}
            />
          </StatItem>
        </TweetStats>
      </TweetFooter>
    </TweetContainer>
  );
};
