// App.js
import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import "./App.css";

import { HelmetProvider } from "react-helmet-async";
import { useAuth0 } from "@auth0/auth0-react";

import { PaymentSuccessPage } from "./pages/PaymentConfirmation";
import { Order } from "./pages/Order";
import SettingsPage from "./pages/SettingPage";
import Library from "./pages/Library";
import LibraryPreview from "./pages/LibraryPreview";
import Agent from "./pages/Agent";
import AdminPage from "./pages/AdminPage";
import PrivacyPolicy from "./pages/PrivacyPolicyPage";
import TermsOfUse from "./pages/TermsOfUsePage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import { LandingPageA } from "./pages/abtests/LandingPageA";
import LandingPageB from "./pages/abtests/LandingPageB";
import { LandingPageC } from "./pages/abtests/LandingPageC";
import TheLeanStartupPage from "./pages/k/TheLeanStartupPage";
import StealthStartupPage from "./pages/k/StealthStartupPage";
import BlogPage from "./pages/BlogPage";
import BlogPost1 from "./pages/BlogPost1";

// Protects routes by checking if the user is verified
const VerifiedRoute = ({ children }) => {
  const { user } = useAuth0();
  if (user && !user.email_verified) {
    return <Navigate to="/verifyEmail" replace />;
  }
  return children;
};

function App() {
  const location = useLocation();
  const [abTestCase, setAbTestCase] = useState(null);

  useEffect(() => {
    // Retrieve or create the metadata object
    let metadata = JSON.parse(localStorage.getItem("metadata")) || {};

    // Parse the query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const params = {};

    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    // Create a journey entry with the current path, params, and a timestamp
    const journeyEntry = {
      path: location.pathname,
      params: params,
      timestamp: new Date().toISOString(),
    };

    // Initialize the journey array if it doesn't exist
    if (!metadata.journey) {
      metadata.journey = [];
    }

    // Append the new journey entry to metadata.journey
    metadata.journey.push(journeyEntry);

    // Keep only the last 5 navigations
    if (metadata.journey.length > 5) {
      metadata.journey = metadata.journey.slice(-5);
    }

    // If there's no existing AB test variant in the metadata, create one
    if (!metadata.abTestVariant) {
      const variants = ["A", "B", "C"];
      metadata.abTestVariant =
        variants[Math.floor(Math.random() * variants.length)];
      metadata.abTestVersion = "4";
    }

    // Persist the updated metadata to localStorage
    localStorage.setItem("metadata", JSON.stringify(metadata));

    // Update our local React state with the chosen variant
    setAbTestCase(metadata.abTestVariant);

    console.log("Updated metadata in localStorage:", metadata);
  }, [location]);

  if (!abTestCase) {
    // Avoid rendering until we know which variant is set
    return null;
  }

  return (
    <StyledApp className="App">
      <HelmetProvider>
        <Routes>
          <Route
            path="/"
            element={
              abTestCase === "A" ? (
                <LandingPageA abTestCase={abTestCase} />
              ) : abTestCase === "B" ? (
                <LandingPageB abTestCase={abTestCase} />
              ) : (
                <LandingPageC abTestCase={abTestCase} />
              )
            }
          />

          <Route path="/payment-success" element={<PaymentSuccessPage />} />
          <Route path="/preorder" element={<Order />} />
          <Route path="/order" element={<Order />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route
            path="/blog/how-to-find-market-demand"
            element={<BlogPost1 />}
          />

          <Route
            path="/settings"
            element={
              <VerifiedRoute>
                <SettingsPage />
              </VerifiedRoute>
            }
          />
          <Route path="/library-preview" element={<LibraryPreview />} />
          <Route
            path="/library"
            element={
              <VerifiedRoute>
                <Library />
              </VerifiedRoute>
            }
          />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/agent" element={<Agent />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/verifyEmail" element={<VerifyEmailPage />} />
          <Route path="/k/stealth-startup" element={<StealthStartupPage />} />
          <Route path="/k/the-lean-startup" element={<TheLeanStartupPage />} />
        </Routes>
      </HelmetProvider>
    </StyledApp>
  );
}

export default App;

const StyledApp = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
