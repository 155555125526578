// SettingsPage.js
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { getUserType, cancelSubscription } from "../functions/apiFunctions";

const SettingsPage = () => {
  const navigate = useNavigate();
  const { user, getAccessTokenSilently } = useAuth0();
  const [canceling, setCanceling] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [userType, setUserType] = useState(null);
  const supportEmail = "support@example.com"; // Change to your support email

  // Fetch the user's subscription type on component mount
  useEffect(() => {
    const fetchUserType = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const data = await getUserType(accessToken);
        setUserType(data.user_type);
      } catch (err) {
        console.error("Error fetching user type", err);
      }
    };

    fetchUserType();
  }, [getAccessTokenSilently]);

  // Handler to cancel subscription.
  const handleCancelSubscription = async () => {
    setCanceling(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const result = await cancelSubscription(accessToken);
      if (result && result.status === "success") {
        setCancelSuccess(true);
      } else {
        console.error("Cancellation failed", result && result.error);
      }
    } catch (err) {
      console.error("Error canceling subscription", err);
    } finally {
      setCanceling(false);
    }
  };

  return (
    <>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
          body {
            margin: 0;
            padding: 0;
            font-family: "Poppins", sans-serif;
            background: #0f172a;
            color: #f8fafc;
          }
        `}
      />
      <Container>
        <Header userType={userType} />
        <ContentWrapper>
          <BackButton onClick={() => navigate("/library")}>
            ← Back to Library
          </BackButton>
          <Card>
            <PageTitle>Subscription Settings</PageTitle>
            <PlanInfo>
              <PlanLabel>Your Current Plan:</PlanLabel>
              <PlanValue>{userType ? userType.toUpperCase() : "N/A"}</PlanValue>
            </PlanInfo>
            <Description>
              You can manage your subscription below. If you choose to cancel,
              your subscription will remain active until the end of your current
              billing period.
            </Description>
            <ButtonRow>
              <CancelButton
                onClick={handleCancelSubscription}
                disabled={canceling || cancelSuccess}
              >
                {canceling
                  ? "Cancelling..."
                  : cancelSuccess
                  ? "Subscription Cancelled"
                  : "Cancel Subscription"}
              </CancelButton>
            </ButtonRow>
            <SupportSection>
              <SupportText>
                Need help? Contact our support team at{" "}
                <SupportEmail href={`mailto:${supportEmail}`}>
                  {supportEmail}
                </SupportEmail>
                .
              </SupportText>
            </SupportSection>
          </Card>
        </ContentWrapper>
        <Footer />
      </Container>
    </>
  );
};

export default withAuthenticationRequired(SettingsPage);

/* ---------------------- Styled Components ---------------------- */

const Container = styled.div`
  min-height: 100vh;
  background: #0f172a;
  color: #f8fafc;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.main`
  max-width: 800px;
  width: 100%;
  margin: 3rem auto;
  padding: 0 1rem;
  position: relative;
`;

const BackButton = styled.button`
  background: transparent;
  border: none;
  color: #10b981;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: underline;
  position: absolute;
  top: -2rem;
  left: 0;
  &:hover {
    color: #34d399;
  }
`;

const Card = styled.div`
  background: #1e293b;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 12px rgba(15, 23, 42, 0.3);
`;

const PageTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const PlanInfo = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlanLabel = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
`;

const PlanValue = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: 0.5rem;
  color: #10b981;
`;

const Description = styled.p`
  font-size: 1rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  text-align: center;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const CancelButton = styled.button`
  background: #ec4899;
  color: #ffffff;
  border: none;
  border-radius: 9999px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s;
  &:hover {
    background: #db2777;
  }
  &:disabled {
    background: #a1a1aa;
    cursor: not-allowed;
  }
`;

const SupportSection = styled.div`
  text-align: center;
`;

const SupportText = styled.p`
  font-size: 0.9rem;
  color: #94a3b8;
`;

const SupportEmail = styled.a`
  color: #10b981;
  text-decoration: underline;
  &:hover {
    color: #34d399;
  }
`;
