// VerifyEmailPage.jsx
import React from "react";
import styled from "@emotion/styled";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const VerifyEmailPage = () => {
  const { user, logout } = useAuth0();
  const navigate = useNavigate();

  const email = user?.email || "your email";

  // Sign out and navigate back to the login page.
  const handleBackToLogin = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <Container>
      <Title>Email Verification</Title>
      <Message>
        A verification email was sent to <strong>{email}</strong>. Please check
        your inbox and click the link provided to verify your account.
      </Message>
      <SupportMessage>
        If you are having issues, please contact support at{" "}
        <MailTo href="mailto:willpayforthis@gmail.com">
          willpayforthis@gmail.com
        </MailTo>
        .
      </SupportMessage>
      <ButtonContainer>
        <BackButton onClick={handleBackToLogin}>Back to Login</BackButton>
      </ButtonContainer>
    </Container>
  );
};

export default VerifyEmailPage;

// Styled Components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  height: 100vh;
  background: #f8fafc;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #1f2937;
`;

const Message = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #374151;
`;

const SupportMessage = styled.p`
  font-size: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #374151;
`;

const MailTo = styled.a`
  color: #6366f1;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const BackButton = styled.button`
  background: #6366f1;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.2s;
  &:hover {
    background: #4f46e5;
  }
`;
